import http from './http';

export const isLoggedIn = () => {
  return !!localStorage.getItem('userId');
};

export const getUserId = () => {
  return localStorage.getItem('userId');
};

export const getUserFullName = () => {
  return localStorage.getItem('fullName');
};

export const getUserSession = () => {
  http
    .get('/users/show.json')
    .then((res) => {
      localStorage.setItem('userId', res.data.id);
      localStorage.setItem('fullName', res.data.full_name);
      localStorage.setItem('token', res.data.token);
    })
    .catch(() => {
      clearUserSession();
      window.location.href = '/';
    });
};

export const clearUserSession = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('fullName');
  localStorage.removeItem('token');
};

export const logOut = async () => {
  http
    .delete('/api/v1/users/sign_out')
    .then(() => {
      localStorage.removeItem('userId');
      localStorage.removeItem('fullName');
      localStorage.removeItem('token');
      localStorage.removeItem('currentCompanyId');
      localStorage.removeItem('userRole');
      sessionStorage.removeItem('currentCompanyId');
      window.location = "/";
    })
    .catch((err) => {
    });
};
