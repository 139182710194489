const ImgHeroSection = ({ props, src, alt = "Hero Section", heading, text }) => {
  const sectionStyle = {
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  return (
    <section className="relative overflow-hidden flex items-center justify-center" style={sectionStyle}>
      <div className="absolute inset-0 bg-black opacity-70 z-0"></div>
      <div className="mt-80 relative z-10 flex flex-col items-start justify-center text-left text-white md:px-28 px-9 py-8">
        <div className="mb-10">
          <h1 className="md:text-5xl text-3xl mb-6">
            { heading }
          </h1>
          <p className="text-xl md:w-3/4">
            { text }
          </p>
        </div>
      </div>
    </section>
  );
};

export default ImgHeroSection;
