import React from "react";
import SetPassword from "../components/SetPassword";

const InviteUserRoutes = [
  {
    path: "set-password/:type/:id/:token",
    element: <SetPassword />,
  },
]

export default InviteUserRoutes;
