import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { BtnTransparent } from './shared/Button';
import http from '../common/http';
import { MdContentCopy } from 'react-icons/md';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import { isSuperAdmin } from '../utils/helper/role';

const Profile = ({ setIsProfileDirty }) => {
  const toast = useRef(null);
  const baseUrl = window.location.origin;
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [userAvatar, setUserAvatar] = useState('');
  const [imgFile, setImgFile] = useState('');
  const [userData, setUserData] = useState({ phone_number: '' });
  const scopedUserId = location.pathname.split('/')[2];
  const [isDisabled, setIsDisabled] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const updateProfile = () => {
    setIsProfileDirty(false);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('avatar', imgFile);
    let params = { user: userData };
    if (isChangingPassword) {
      params.user.current_password = currentPassword;
      params.user.password = newPassword;
      params.user.password_confirmation = confirmPassword;
    }
    Object.entries(params.user).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          value.forEach((val) => formData.append(`${key}[]`, val));
        } else {
          formData.append(`${key}[]`, []);
        }
      } else {
        formData.append(key, value);
      }
    });
    http
      .put(`/api/v1/users/${scopedUserId}`, formData, headers)
      .then(() => {
        showMessage('success', 'Success', 'Profile updated successfully.', 'bg-teal-100 text-teal-500');
        setIsChangingPassword(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((err) => {
        showMessage('error', 'Error', `Sorry, there was an error while updating your profile. ${err.response?.data?.message}`, 'bg-red-100 text-red-700');
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImgFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageSrc = e.target.result;
        setUserAvatar(imageSrc);
      };
      reader.readAsDataURL(file);
    }
  };
  const fetchProfileInfo = () => {
    http
      .get(`/api/v1/profile/${scopedUserId}`)
      .then((res) => {
        setUserData(res.data.user);
        setUserAvatar(res.data.user.avatar_url);
        if (isSuperAdmin()) {
          sessionStorage.setItem('currentCompanyId', res.data.user.company_id);
        } else {
          localStorage.setItem('currentCompanyId', res.data.user.company_id);
        }

      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching profile data.', 'bg-red-100 text-red-700');
      });
  };
  const handleInput = (fieldName, newValue) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));
  };
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const copyReferralLink = async (text) => {
    try {
      setIsDisabled(true);
      await navigator.clipboard.writeText(text);
      showMessage('success', 'Success', 'Referral link copied to clipboard', 'bg-teal-100 text-teal-500');
      setTimeout(() => setIsDisabled(false), 3000);
    } catch (err) {
      showMessage('error', 'Error', 'Failed to copy', 'bg-red-100 text-red-700');
      setIsDisabled(false);
    }
  };

  const referralLink = `${baseUrl}/sign-up?referral_code=${userData.referral_code}`;

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      showMessage('error', 'Error', 'Passwords do not match.', 'bg-red-100 text-red-700');
      return false;
    }
    return true;
  };

  const togglePasswordChange = () => {
    setIsChangingPassword(!isChangingPassword);
  };

  return (
    <div className='flex w-full flex-col'>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
        <div className='text-2xl font-normal mb-8 font-playfair'>Profile</div>
        {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            className='text-white w-full h-full'
            name='Save changes'
            handleClick={() => {
              if (isChangingPassword && !validatePasswords()) return;
              updateProfile();
            }}
          />
        </div>}
      </div>
      <div>
        <div className='flex w-full justify-between lg:items-start items-center lg:flex-row flex-col'>
          {isMobile && <div className='lg:justify-start justify-center pb-7'>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={ (e) => { handleFileChange(e); setIsProfileDirty(true); } }
            />
            {
              userAvatar ?
              <Avatar
                alt="User avatar"
                image={userAvatar}
                size="xlarge"
                shape='circle'
                className="user-avatar"
                onClick={() => fileInputRef.current.click()}
              /> :
              <Avatar
                icon="pi pi-user"
                size="xlarge"
                shape='circle'
                onClick={() => fileInputRef.current.click()}
              />
            }
          </div>}
          <div className='flex w-full justify-between flex-col lg:w-3/4'>
            <div className='flex w-full flex-col' >
              <div className='text-base mb-2 font-bold font-monsterrat'>
                General Information
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    First name
                  </label>
                  <input
                    type="text"
                    key='firstName'
                    value={userData.first_name}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('first_name', e.target.value); setIsProfileDirty(true); } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Last name
                  </label>
                  <input
                    type="text"
                    key='lastName'
                    value={userData.last_name}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('last_name', e.target.value); setIsProfileDirty(true); } }
                  />
                </div>
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Phone number
                  </label>
                  <input
                    type="text"
                    key='phoneNumber'
                    value={userData.phone_number}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('phone_number', e.target.value); setIsProfileDirty(true); } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Email
                  </label>
                  <input
                    type="email"
                    key='email'
                    value={userData.email}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('email', e.target.value); setIsProfileDirty(true); } }
                  />
                </div>
              </div>

              <div className='mt-8'>
                <button
                  type='button'
                  className='underline text-sm font-normal text-moderate-orange'
                  onClick={togglePasswordChange}
                >
                  {isChangingPassword ? 'Cancel Password Change' : 'Change Password'}
                </button>
              </div>
              {isChangingPassword && (
                <>
                  <div className='flex gap-x-16 lg:flex-row flex-col'>
                    <div className='mt-4 w-100% sm:w-[280px]'>
                      <label className='text-sm font-normal my-4'>
                        Current Password
                      </label>
                      <input
                        type='password'
                        value={currentPassword}
                        className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className='mt-4 w-100% sm:w-[280px]'>
                      <label className='text-sm font-normal my-4'>
                        New Password
                      </label>
                      <input
                        type='password'
                        value={newPassword}
                        className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='mt-4 w-100% sm:w-[280px]'>
                    <label className='text-sm font-normal my-4'>
                      Confirm New Password
                    </label>
                    <input
                      type='password'
                      value={confirmPassword}
                      className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Referral Code
                  </label>
                  <input
                    type="text"
                    key='referralCode'
                    value={userData.referral_code}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    disabled
                  />
                </div>
              </div>
              <div className='mt-4'>
                <label className='text-sm font-normal my-4'>
                  Referral Link
                </label>
                <div className="flex w-full items-center">
                  <input
                    type="text"
                    key='referralLink'
                    value={referralLink}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    disabled
                  />
                  <button
                    type="button"
                    className='ml-2'
                    disabled={isDisabled}
                    onClick={() => copyReferralLink(referralLink)}
                  >
                    <MdContentCopy size={30} className={`cursor-pointer`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className='lg:justify-end justify-center flex pb-7'>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={ (e) => { handleFileChange(e); setIsProfileDirty(true); } }
              />
              {
                userAvatar ?
                <Avatar
                  alt="User avatar"
                  image={userAvatar}
                  size="xlarge"
                  shape='circle'
                  className="mt-2 user-avatar"
                  onClick={() => fileInputRef.current.click()}
                /> :
                <Avatar
                  icon="pi pi-user"
                  size="xlarge"
                  shape='circle'
                  className="mt-2"
                  onClick={() => fileInputRef.current.click()}
                />
              }
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className='w-full h-[50px] fixed bottom-0 left-0'>
          <BtnTransparent
            className='text-white font-semibold w-full h-full'
            name='Save changes'
            handleClick={() => {
              if (isChangingPassword && !validatePasswords()) return;
              updateProfile();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Profile;
