
import React from "react";
import ImgHeroSection from "./ImgHeroSection";
import heroImg from '../assets/images/section.webp';

const TermAndCondition = () => {

  return (
    <div>
      <ImgHeroSection src={heroImg} heading="Terms & Conditions"/>
      <div className='relative z-30 bg-white' id="leadForm">
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="md:text-4xl text-2xl text-center font-semibold pb-10">Terms and Conditions for Quick Dispatch LLC</h2>
          <p className="text-lg text-moderate-orange text-left pb-5">Effective Date: September 23, 2024</p>
          <ol class="list-decimal md:text-xl leading-normal md:text-justify">
            <li>
              <b>Introduction</b><br/>
              Welcome to www.DispatchQuick.com (the “Site”). These terms and conditions (“Terms”) apply to your use of the Site. By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Site.
            </li>
            <li>
              <b>Use of the Site</b>
              <ul class="list-disc ml-5 xs:ml-1">
                <li>
                  <b>Eligibility:</b> You must be at least 18 years old to use the Site.
                </li>
                <li>
                  <b>User Conduct:</b> You agree not to use the Site for any unlawful purpose or in any way that might harm, damage, or disparage any other party.
                </li>
                <li>
                  <b>Account Security:</b> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                </li>
              </ul>
            </li>
            <li>
              <b>Services</b><br/>
              Quick Dispatch LLC provides truck dispatching services. The specific terms and conditions of these services will be outlined in separate agreements between Quick Dispatch LLC and its clients.
            </li>
            <li>
              <b>Intellectual Property</b><br/>
              All content on the Site, including text, graphics, logos, images, and software, is the property of Quick Dispatch LLC or its content suppliers and is protected by copyright, trademark, and other laws.
            </li>
            <li>
              <b>Limitation of Liability</b><br/>
              To the fullest extent permitted by law, Quick Dispatch LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
              <ul class="list-disc ml-5 xs:ml-1">
                <li>
                  Your use of or inability to use the Site.
                </li>
                <li>
                  Any unauthorized access to or use of our servers and/or any personal information stored therein.
                </li>
                <li>
                  Any interruption or cessation of transmission to or from the Site.
                </li>
                <li>
                  Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party.
                </li>
                <li>
                  Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site.
                </li>
              </ul>
            </li>
            <li>
              <b>Governing Law</b><br/>
              These Terms shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of law principles.
            </li>
            <li>
              <b>Changes to These Terms</b><br/>
              We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the Site. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.
            </li>
            <li>
              <b>Dispute Resolution</b><br/>
              Any disputes arising out of or relating to these Terms shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
            </li>
            <li>
              <b>Indemnity</b><br/>
              You agree to indemnify, defend, and hold harmless Quick Dispatch LLC and its affiliates from any claims, damages, losses, liabilities, and expenses (including reasonable attorneys’ fees) arising out of your use of the Site or your violation of these Terms.
            </li>
            <li>
              <b>Contact Us</b><br/>
              If you have any questions about these Terms, please contact us at:
              <ul class="list-disc ml-5 xs:ml-1">
                <li className="mb-2">
                  <a href="tel:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Call: (281) 790-9452</a>
                </li>
                <li>
                  <a href="mailto:info@dispatchquick.com" className="text-moderate-orange hover:text-light-moderate-orange">Email: info@dispatchquick.com</a>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermAndCondition;
