import http from '../../common/http';
import React, { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import ImgHeroSection from "../ImgHeroSection";
import { BtnTransparent } from '../shared/Button';
import './GetStarted.scss';
import heroImg from '../../assets/images/section.webp';
import { useToast } from '../../contexts/ToastContext';

const GetStarted = () => {
  
  const initialFormData = {
    mc: "",
    dot: "",
    companyName: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    companyEmail: "",
    email: "",
    address: "",
    insuranceCompany: "",
    insuranceContactName: "",
    insurancePhoneNumber: "",
    factoringCompanyName: "",
    factoringCompanyContactName: "",
    factoringCompanyPhoneNumber: "",
    typeEquipments: "",
    preferredStates: "",
    driverCount: "",
    truckCount: "",
    ein: "",
    files: {
      mcFile: "",
      dotFile: "",
      w9File: "",
      insuranceFile: "",
    },
    uploadedFileNames: {
      mcFile: "",
      dotFile: "",
      w9File: "",
      insuranceFile: "",
    },
    uploadKeys: {
      mcFile: 0,
      dotFile: 0,
      w9File: 0,
      insuranceFile: 0,
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const id = hash.substring(1);
      const element = document.getElementById(id);

      if (element) {
        const top = element.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (e, fieldName) => {
    const file = e.files[0];
    setFormData({
      ...formData,
      files: {
        ...formData.files,
        [fieldName]: file,
      },
      uploadedFileNames: {
        ...formData.uploadedFileNames,
        [fieldName]: file.name,
      },
    });
  };

  const handleRemoveFile = (fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      files: {
        ...prevState.files,
        [fieldName]: null,
      },
      uploadedFileNames: {
        ...prevState.uploadedFileNames,
        [fieldName]: "",
      },
      uploadKeys: {
        ...prevState.uploadKeys,
        [fieldName]: (prevState.uploadKeys[fieldName] || 0) + 1,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (["mc", "dot", "companyName", "phoneNumber", "firstName", "lastName", "email", "insuranceCompany", "insurancePhoneNumber", "insuranceContactName", "typeEquipments", "ein", "companyEmail"].includes(key) && !formData[key]) {
        newErrors[key] = "This field is required.";
      }
    });

    Object.keys(formData.uploadedFileNames).forEach(key => {
      if (["mcFile","dotFile","w9File","insuranceFile"].includes(key) && !formData.uploadedFileNames[key]) {
        newErrors[key] = "This file is required.";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...newErrors });
      return;
    } 
    setIsSubmitting(true);
    const formPayload = new FormData();
    for (const key in formData) {
      if (key === "files") {
        for (const fileKey in formData.files) {
          if (formData.files[fileKey]) {
            formPayload.append(`lead[${fileKey}]`, formData.files[fileKey]);
          }
        }
      } else {
        formPayload.append(`lead[${key}]`, formData[key]);
      }
    }

    setIsButtonDisabled(true);

    let submitUrl = "/api/v1/leads";
    submitUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT ? `${process.env.REACT_APP_BACKEND_API_ENDPOINT}${submitUrl}` : submitUrl;

    http
      .post(submitUrl, formPayload)
      .then(() => {
        setIsButtonDisabled(false);
        setFormData(initialFormData);
        setErrors({});
        showToast('success', "Thank you for reaching out to us. We'll be in touch soon.");
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        let errorMessage = error.response ? error.response.data.message : "Something went wrong. Please try again or contact support for any assistance."
        showToast('error', errorMessage);
        setIsSubmitting(false);
      });
  };

  const inputCss = "w-full p-2 bg-transparent border-b-2 border-gray-500 focus:outline-none focus:border-moderate-orange peer";

  return (
    <div>
      <ImgHeroSection src={heroImg} heading="Get Started with Truck Dispatching"/>
      <div className='relative z-30 bg-white' id="leadForm">
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="md:text-4xl text-2xl text-center font-semibold pb-10">OWNER-OP/ CARRIER APPLICATION</h2>
          <form onSubmit={handleSubmit} className="p-6 border border-gray-300 shadow-md rounded-lg bg-white space-y-4">
            <p className="text-xl text-center font-semibold my-2">COMPANY INFORMATION</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="mc"
                  name="mc"
                  value={formData.mc}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="mc"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.mc ? "top-0 text-xs" : " top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  MC*
                </label>
                {errors.mc && <span className="text-red-500 text-xs">{errors.mc}</span>}
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  id="dot"
                  name="dot"
                  value={formData.dot}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="dot"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.dot ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  DOT*
                </label>
                {errors.dot && <span className="text-red-500 text-xs">{errors.dot}</span>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full pr-2">
                <input
                  type="text"
                  id="ein"
                  name="ein"
                  value={formData.ein}
                  onChange={handleInputChange}
                  className={inputCss}
                  required={'ein'}
                />
                <label
                  htmlFor="ein"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.ein ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  EIN*
                </label>
                {errors.ein && <span className="text-red-500 text-xs">{errors.ein}</span>}
              </div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="companyName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.companyName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Company Name / DBA*
                </label>
                {errors.companyName && <span className="text-red-500 text-xs">{errors.companyName}</span>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="firstName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.firstName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  First Name*
                </label>
                {errors.firstName && <span className="text-red-500 text-xs">{errors.firstName}</span>}
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="lastName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.lastName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Last Name*
                </label>
                {errors.lastName && <span className="text-red-500 text-xs">{errors.lastName}</span>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="phoneNumber"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.phoneNumber ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Phone Number #*
                </label>
                {errors.phoneNumber && <span className="text-red-500 text-xs">{errors.phoneNumber}</span>}
              </div>

              <div className="relative w-full">
                <input
                  type="email"
                  id="companyEmail"
                  name="companyEmail"
                  value={formData.companyEmail}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="companyEmail"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.companyEmail ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Email*
                </label>
                {errors.companyEmail && <span className="text-red-500 text-xs">{errors.companyEmail}</span>}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="number"
                  id="driverCount"
                  name="driverCount"
                  value={formData.driverCount}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="driverCount"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.driverCount ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Driver Count
                </label>
              </div>

              <div className="relative w-full">
                <input
                  type="number"
                  id="truckCount"
                  name="truckCount"
                  value={formData.truckCount}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="truckCount"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.truckCount ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Truck Count
                </label>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="typeEquipments"
                  name="typeEquipments"
                  value={formData.typeEquipments}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="typeEquipments"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.typeEquipments ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Type Equipment*
                </label>
                {errors.typeEquipments && <span className="text-red-500 text-xs">{errors.typeEquipments}</span>}
              </div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="preferredStates"
                  name="preferredStates"
                  value={formData.preferredStates}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="preferredStates"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.preferredStates ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Preferred States
                </label>
              </div>
            </div>

            <div><p className="text-xl text-center font-semibold mt-12">INSURANCE INFORMATION</p></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="insuranceCompany"
                  name="insuranceCompany"
                  value={formData.insuranceCompany}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="insuranceCompany"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.insuranceCompany ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Insurance Company*
                </label>
                {errors.insuranceCompany && <span className="text-red-500 text-xs">{errors.insuranceCompany}</span>}
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  id="insuranceContactName"
                  name="insuranceContactName"
                  value={formData.insuranceContactName}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="insuranceContactName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.insuranceContactName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Insurance Contact Name*
                </label>
                {errors.insuranceContactName && <span className="text-red-500 text-xs">{errors.insuranceContactName}</span>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="insurancePhoneNumber"
                  name="insurancePhoneNumber"
                  value={formData.insurancePhoneNumber}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="insurancePhoneNumber"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.insurancePhoneNumber ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Insurance Phone Number*
                </label>
                {errors.insurancePhoneNumber && <span className="text-red-500 text-xs">{errors.insurancePhoneNumber}</span>}
              </div>

              <div className="relative w-full">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={inputCss}
                  required
                />
                <label
                  htmlFor="email"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.email ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Email*
                </label>
                {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="address"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.address ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Address
                </label>
              </div>
            </div>

            <div><p className="text-xl text-center font-semibold mt-12">FACTORING INFORMATION</p></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  id="factoringCompanyName"
                  name="factoringCompanyName"
                  value={formData.factoringCompanyName}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="factoringCompanyName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.factoringCompanyName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Name of the Factoring Company
                </label>
              </div>
              <div className="relative w-full">
                <input
                  type="text"
                  id="factoringCompanyContactName"
                  name="factoringCompanyContactName"
                  value={formData.factoringCompanyContactName}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="factoringCompanyContactName"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.factoringCompanyContactName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Factoring Company Contact Name
                </label>
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  id="factoringCompanyPhoneNumber"
                  name="factoringCompanyPhoneNumber"
                  value={formData.factoringCompanyPhoneNumber}
                  onChange={handleInputChange}
                  className={inputCss}
                />
                <label
                  htmlFor="factoringCompanyPhoneNumber"
                  className={`absolute left-0 -translate-y-1/2 text-gray-600 transition-all duration-200 
                  ${formData.factoringCompanyPhoneNumber ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"} peer-focus:top-0 peer-focus:text-xs`}
                >
                  Factoring Company Phone Number
                </label>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative w-full">
                <FileUpload
                  key={formData.uploadKeys.mcFile}
                  name="mcFile"
                  customUpload
                  auto
                  uploadHandler={(e) => handleFileUpload(e, "mcFile")}
                  mode="basic"
                  chooseLabel="Upload MC#"
                />
                {errors.mcFile && <span className="text-red-500 text-xs">{errors.mcFile}</span>}
                {formData.uploadedFileNames.mcFile && (
                  <div className="flex items-center justify-between mt-2">
                    <span>{formData.uploadedFileNames.mcFile}</span>
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text"
                      onClick={() => handleRemoveFile("mcFile")}
                    />
                  </div>
                )}
              </div>
            
              <div className="relative w-full">
                <FileUpload
                  key={formData.uploadKeys.dotFile}
                  name="dotFile"
                  customUpload
                  auto
                  uploadHandler={(e) => handleFileUpload(e, "dotFile")}
                  mode="basic"
                  chooseLabel="Upload dot"
                  className=""
                />
                {errors.dotFile && <span className="text-red-500 text-xs">{errors.dotFile}</span>}
                {formData.uploadedFileNames.dotFile && (
                  <div className="flex items-center justify-between mt-2">
                    <span>{formData.uploadedFileNames.dotFile}</span>
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text"
                      onClick={() => handleRemoveFile("dotFile")}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative w-full">
                <FileUpload
                  key={formData.uploadKeys.w9File}
                  name="w9File"
                  customUpload
                  auto
                  uploadHandler={(e) => handleFileUpload(e, "w9File")}
                  mode="basic"
                  chooseLabel="Upload w9"
                  className=""
                />
                {errors.w9File && <span className="text-red-500 text-xs">{errors.w9File}</span>}
                {formData.uploadedFileNames.w9File && (
                  <div className="flex items-center justify-between mt-2">
                    <span>{formData.uploadedFileNames.w9File}</span>
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text"
                      onClick={() => handleRemoveFile("w9File")}
                    />
                  </div>
                )}
              </div>


              <div className="relative w-full">
                <FileUpload
                  key={formData.uploadKeys.insuranceFile}
                  name="insuranceFile"
                  customUpload
                  auto
                  uploadHandler={(e) => handleFileUpload(e, "insuranceFile")}
                  mode="basic"
                  chooseLabel="Upload insurance#"
                  className=""
                />
                {errors.insuranceFile && <span className="text-red-500 text-xs">{errors.insuranceFile}</span>}
                {formData.uploadedFileNames.insuranceFile && (
                  <div className="flex items-center justify-between mt-2">
                    <span>{formData.uploadedFileNames.insuranceFile}</span>
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text"
                      onClick={() => handleRemoveFile("insuranceFile")}
                    />
                  </div>
                )}
              </div>

            </div>


            <div className="flex justify-end mt-6">
              <BtnTransparent
                name="Submit"
                disabled={isButtonDisabled}
                isSubmitting={isSubmitting}
                handleClick={handleSubmit}
                className="md:text-lg"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
