import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import LandingPageRoutes from "./LandingPageRoutes";
import NotFound from "../components/NotFound";
import ProfilePageRoutes from "./ProfileRoutes";
import InviteUserRoutes from "./InviteUserRoutes";
import AdminPageRoutes from "./AdminRoutes";

const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      ...LandingPageRoutes,
      ...ProfilePageRoutes,
      ...InviteUserRoutes,
      ...AdminPageRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

const BrowserRouter = createBrowserRouter(routes);
export default BrowserRouter;
