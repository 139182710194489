import Testimonial from './testimonial/Testimonial';
import Faq from './Faq';
import HeroSection from './HeroSection';

export const Home = () => {
  return (
    <div>
      <HeroSection />
      <Testimonial />
      <Faq />
    </div>
  );
};
