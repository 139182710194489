import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { FaTimes } from 'react-icons/fa';
import { BtnTransparent } from '../shared/Button';
import  './modal.scss';
const DocumentModal = ({ visible, onHide, document, onSave, isLoading }) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const fileUploadRef = useRef(null);

  useEffect(() => {
    if (document) {
      setName(document.name || '');
      setFileName(document.file_name || '');
      setFileUrl(document.file_url || '');
    } else {
      setName('');
      setFile(null);
      setFileName('');
      setFileUrl('');
    }
  }, [document]);

  const handleFileSelect = (event) => {
    setFile(event.files[0]);
    setFileName(event.files[0].name);
    setErrorMessage('');

    if (fileUploadRef.current) {
      setTimeout(() => fileUploadRef.current.clear(), 100);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileName('');
    setFileUrl('');
    setErrorMessage('');

    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleSave = () => {
    if (!name) {
      setErrorMessage('Please enter the attachment name.');
      return;
    } else if (!file && !fileUrl) {
      setErrorMessage('Please add an attachment.');
      return;
    }

    const formData = new FormData();
    formData.append('document[name]', name);
    if (file) {
      formData.append('document[file]', file);
    }

    onSave(formData);
    setErrorMessage('');
  };

  return (
    <Dialog
      header={document ? "Edit Document" : "Add Document"}
      visible={visible}
      onHide={onHide}
      headerClassName="text-white"
      className="w-1/3 custom-content"
    >
      <div className="p-4">
        <div className="mb-6">
          <label htmlFor="name" className="block text-sm font-medium text-white pb-2">
            Name
          </label>
          <InputText
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`mt-1 w-full border-2 border-moderate-orange p-2 rounded-lg`}
          />
        </div>
        <div className="mb-3 space-y-4">
          <label htmlFor="file" className="block text-sm font-medium text-white">
            Attachment
          </label>
          <FileUpload
            ref={fileUploadRef}
            id="file"
            customUpload
            mode="basic"
            auto
            chooseLabel={file ? 'Change File' : 'Choose'}
            onSelect={handleFileSelect}
            className="w-full"
          />
          {fileName && !fileUrl && (
            <div className="flex items-center mt-2">
              <span className="text-sm text-white">{fileName}</span>
              <FaTimes
                className="ml-2 text-red-500 cursor-pointer"
                onClick={handleRemoveFile}
              />
            </div>
          )}
          {fileUrl && (
            <div className="mt-4">
              <a
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                <div className="flex items-center">
                  <span className="text-sm">{fileName}</span>
                  <FaTimes
                    className="ml-2 text-red-500 cursor-pointer"
                    onClick={handleRemoveFile}
                  />
                </div>
              </a>
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="mt-4 text-red-500">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end">
          <BtnTransparent
            name="Save"
            handleClick={handleSave}
            className="mt-6"
            isSubmitting={isLoading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DocumentModal;
