import React, { useEffect } from 'react';
import logo from '../assets/images/QuickDispatch-logo.png';
const SplashScreen = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="text-center">
        <img src={logo} alt="Quick Dispatch" className="h-20 mx-auto mb-4" />
        <h1 className="text-3xl font-bold">Quick Dispatch</h1>
      </div>
    </div>
  );
};

export default SplashScreen;
