import React, { createContext, useContext, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  const showToast = (type, message) => {
    if (toast.current) {
      const severity = type === "success" ? "success" : "error";
      const summary = type === "success" ? "Success" : "Error";
      const detail = message;
      const style = type === "success"
        ? "bg-teal-100 text-teal-500"
        : "bg-red-100 text-red-700";

      toast.current.show({
        severity,
        summary,
        detail,
        life: 3000,
        className: style,
        contentClassName: "p-2.5",
      });
    }
  };

  return (
    <ToastContext.Provider value={showToast}>
      <Toast ref={toast} position="bottom-right" />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
