import React, { useState, useEffect } from 'react';
import http from '../../common/http';
import { BtnTransparent } from '../shared/Button';
import { useToast } from '../../contexts/ToastContext';

const Notes = () => {
  const [notes, setNotes] = useState({text: ""});
  const showToast = useToast();

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleChange = (e) => {
    setNotes((oldNote) => ({
      ...oldNote, 
      text: e.target.value
    }));
  };


  const fetchNotes = () => {
    http
      .get('/api/v1/notes')
      .then((res) => {
        if (res.data) {
          setNotes(res.data);
        }
      })
      .catch(() => {
        showToast('error', `Failed to fetch notes`);
      });
  };

  const handleSaveNotes = (n) => {
    if (notes && notes.id) {
     
      http
        .put(`/api/v1/notes/${notes.id}`, notes)
        .then(() => {
          showToast('success', 'Notes updated successfully');
          fetchNotes();
        })
        .catch((error) => {
          showToast('error', `Failed to update notes. ${error.response?.data?.message}`);
        });
    } else {
     
      http
        .post('/api/v1/notes', notes)
        .then((res) => {
          setNotes(res.data);
          showToast('success', 'Notes updated successfully');
        })
        .catch((error) => {
          showToast('error', `Failed to create notes. ${error.response?.data?.message}`);
        });
    }
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Notes</h2>
        <BtnTransparent name="Update Notes" handleClick={handleSaveNotes} />
      </div>
      <textarea
        className="w-full border border-moderate-orange rounded p-2"
        value={notes.text}
        onChange={handleChange}
        placeholder="Type notes here..."
        rows="10"
      />
    </div>
  );
};

export default Notes;
