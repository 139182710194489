import React, { createContext, useContext, useState } from 'react';

const DispatchFormContext = createContext();

export const useDispatchForm = () => {
  return useContext(DispatchFormContext);
};

export const DispatchFormProvider = ({ children }) => {
  const [isDispatchFormVisible, setDispatchFormVisible] = useState(false);
  const [lock, setLock] = useState(false);

  const showDispatchForm = () => {
    if (!lock) {
      setLock(true);
      setDispatchFormVisible(true);
    }
  };

  const hideDispatchForm = () => {
    setLock(false);
    setDispatchFormVisible(false);
  };

  return (
    <DispatchFormContext.Provider
      value={{
        isDispatchFormVisible,
        showDispatchForm,
        hideDispatchForm,
      }}
    >
      {children}
    </DispatchFormContext.Provider>
  );
};
