import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { BtnTransparent } from '../shared/Button';
import { FaTimes } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';

export default function LoadModal({ visible, onHide, loadData, onSave, drivers, isLoading }) {
  const initialFormData = {
    truck_number: '',
    load_number: '',
    special_instructions: '',
    pickup_date: null,
    pickup_location: '',
    dropoff_date: null,
    dropoff_location: '',
    driver_id: '',
    total_amount: 0,
    percentage: 0,
    gross_pay: 0,
    additional_fees: 0,
    attachments: [],
  };

  const initialErrorState = {
    truck_number: "",
    load_number: "",
    special_instructions: "",
    pickup_date: "",
    pickup_location: "",
    dropoff_date: "",
    dropoff_location: "",
    driver_id: "",
    total_amount: "",
    percentage: "",
    gross_pay: "",
    additional_fees: "",
    attachments: "",
  };

  const [errors, setErrors] = useState(initialErrorState);

  const [formData, setFormData] = useState(initialFormData);
  const fileUploadRef = useRef(null);
  const [filesToRemove, setFilesToRemove] = useState([]);

  useEffect(() => {
    if (loadData) {
      setFormData({
        ...initialFormData,
        ...loadData,
        attachments: loadData.attachments || [],
      });
    } else {
      setFormData(initialFormData);
    }
    setErrors(initialErrorState);
  }, [loadData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'number' ? parseFloat(value) : value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleDriverChange = (e) => {
    setFormData({ ...formData, driver_id: e.value || '' });
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.files[0];
    const updatedAttachments = [
      ...formData.attachments,
      { file: selectedFile, file_name: selectedFile.name, file_url: URL.createObjectURL(selectedFile) },
    ];
    setFormData({ ...formData, attachments: updatedAttachments });
    
    if (fileUploadRef.current) {
      setTimeout(() => fileUploadRef.current.clear(), 100);
    }
    setErrors({ ...errors, attachments: '' });
  };

  const handleRemoveAttachment = (index) => {
    if (formData.attachments[index].file_id) {
      setFilesToRemove([...filesToRemove, formData.attachments[index].file_id]);
    }

    const updatedAttachments = formData.attachments.filter((_, i) => i !== index);
    setFormData({ ...formData, attachments: updatedAttachments });
  };

  const handleSubmit = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "driver_id" && !formData[key]) {
        newErrors[key] = `${key.replace(/_/g, ' ')} is required`;
      }
    });

    if (Object.keys(newErrors).length === 0) {
      const formDataObj = new FormData();
      
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'attachments') {
          formDataObj.append(`load[${key}]`, value);
        }
      });
    
      formData.attachments.forEach((attachment) => {
        if (attachment.file) {
          formDataObj.append('load[attachments][]', attachment.file);
        }
      });

      filesToRemove.forEach((fileId) => {
        formDataObj.append('load[remove_attachments][]', fileId);
      });
    
      if (onSave(formDataObj)) setFormData(initialFormData);
    } else {
      setErrors(newErrors);
    }
  };

  const inputFieldStyle = 'w-full border border-gray-300 rounded p-2';

  const footer = (
    <BtnTransparent
      name={loadData?.id ? 'Update' : 'Save'}
      className="float-right mt-4"
      handleClick={handleSubmit}
      isSubmitting={isLoading}
    />
  );

  return (
    <Dialog
      header="Load Details"
      visible={visible}
      onHide={onHide}
      style={{ width: '95vw', maxWidth: '1024px' }}
      className="p-4"
      headerClassName="bg-blue-600 text-white"
      footer={footer}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="mb-3">
          <label htmlFor="truck_number" className="text-white">Truck Number</label>
          <InputText id="truck_number" name="truck_number" value={formData.truck_number} onChange={handleChange} className={inputFieldStyle} />
          {errors['truck_number'] && (
            <small className="p-error">{errors['truck_number']}</small>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="load_number" className="text-white">Load Number</label>
          <InputText id="load_number" name="load_number" value={formData.load_number} onChange={handleChange} className={inputFieldStyle} />
          {errors['load_number'] && (
            <small className="p-error">{errors['load_number']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="pickup_date" className="text-white">Pickup Date</label>
          <Calendar
            id="pickup_date"
            value={formData.pickup_date}
            onChange={(e) => handleDateChange('pickup_date', e.value)}
            showIcon
            className={inputFieldStyle}
          />
          {errors['pickup_date'] && (
            <small className="p-error">{errors['pickup_date']}</small>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="pickup_location" className="text-white">Pickup Location</label>
          <InputText id="pickup_location" name="pickup_location" value={formData.pickup_location} onChange={handleChange} className={inputFieldStyle} />
          {errors['pickup_location'] && (
            <small className="p-error">{errors['pickup_location']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="dropoff_date" className="text-white">Dropoff Date</label>
          <Calendar
            id="dropoff_date"
            value={formData.dropoff_date}
            onChange={(e) => handleDateChange('dropoff_date', e.value)}
            showIcon
            className={inputFieldStyle}
          />
          {errors['dropoff_date'] && (
            <small className="p-error">{errors['dropoff_date']}</small>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="dropoff_location" className="text-white">Dropoff Location</label>
          <InputText id="dropoff_location" name="dropoff_location" value={formData.dropoff_location} onChange={handleChange} className={inputFieldStyle} />
          {errors['dropoff_location'] && (
            <small className="p-error">{errors['dropoff_location']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="total_amount" className="block text-white mb-2">Total Amount</label>
          <InputText
            id="total_amount"
            name="total_amount"
            type="number"
            value={formData.total_amount}
            onChange={handleChange}
            step="0.01"
            placeholder="Enter total amount"
            className={inputFieldStyle}
          />
          {errors['total_amount'] && (
            <small className="p-error">{errors['total_amount']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="percentage" className="text-white mb-2">Percentage</label>
          <InputText
            id="percentage"
            name="percentage"
            type="number"
            value={formData.percentage}
            onChange={handleChange}
            step="0.01"
            min="0"
            max="100"
            placeholder="Enter percentage"
            className={inputFieldStyle}
          />
          {errors['percentage'] && (
            <small className="p-error">{errors['percentage']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="additional_fees" className="text-white">Additional Fees</label>
          <InputText
            id="additional_fees"
            name="additional_fees"
            type="number"
            value={formData.additional_fees}
            onChange={handleChange}
            step="0.01"
            placeholder="Enter additional fees"
            className={inputFieldStyle}
          />
          {errors['additional_fees'] && (
            <small className="p-error">{errors['additional_fees']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="gross_pay" className="text-white mb-2">Gross Pay</label>
          <InputText
            id="gross_pay"
            name="gross_pay"
            type="number"
            value={formData.gross_pay}
            onChange={handleChange}
            step="0.01"
            placeholder="Enter gross pay"
            className={inputFieldStyle}
          />
          {errors['gross_pay'] && (
            <small className="p-error">{errors['gross_pay']}</small>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="driver_id" className="text-white">Driver</label>
          <Dropdown
            id="driver_id"
            value={formData.driver_id}
            options={drivers.map(driver => ({ label: `${driver.first_name} ${driver.last_name}`, value: driver.id }))}
            onChange={handleDriverChange}
            showClear
            placeholder="Select Driver"
            className="w-full"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="special_instructions" className="text-white">Special Instructions</label>
          <InputTextarea
            id="special_instructions"
            name="special_instructions"
            value={formData.special_instructions}
            rows={5}
            className={inputFieldStyle}
            onChange={handleChange}
          />
          {errors['special_instructions'] && (
            <small className="p-error">{errors['special_instructions']}</small>
          )}
        </div>

        <div className="mb-3 col-span-2">
          <label htmlFor="file" className="text-white">Attachments</label>
          <FileUpload
            ref={fileUploadRef}
            id="file"
            customUpload
            mode="basic"
            auto
            chooseLabel="Choose"
            onSelect={handleFileSelect}
            className="w-full mt-1"
          />
          {errors['attachments'] && (
            <small className="p-error">{errors['attachments']}</small>
          )}
          {formData.attachments.length > 0 && (
            <div className="mt-2">
              {formData.attachments.map((attachment, index) => (
                <div key={index} className="flex items-center mb-2">
                  <a
                    href={attachment.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-500 underline mr-2"
                  >
                    {attachment.name || attachment.file_name}
                  </a>
                  <FaTimes
                    className="text-red-500 cursor-pointer"
                    onClick={() => handleRemoveAttachment(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
