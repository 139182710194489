
import React from "react";
import ImgHeroSection from "./ImgHeroSection";
import heroImg from '../assets/images/section.webp';

const Pricing = () => {

  return (
    <div>
      <ImgHeroSection src={heroImg} heading="Get Started with Truck Dispatching"/>
      <div className='relative z-30 bg-white' id="leadForm">
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="md:text-4xl text-2xl text-center font-semibold pb-10">Pricing</h2>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
