import React, { useEffect } from 'react';
import { useDispatchForm } from '../contexts/DispatchFormProvider';
import { DispatchForm } from '../components/dispatch-form/DispatchForm';
const DispatchFormWrapper = () => {
  const { isDispatchFormVisible, showDispatchForm, hideDispatchForm } = useDispatchForm();

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      showDispatchForm();
    }, 300000000);

    return () => {
      clearTimeout(initialTimeout);
    };
  }, []);

  return (
    <DispatchForm 
      isViewDialog={isDispatchFormVisible} 
      handleModal={hideDispatchForm} 
    />
  );
};

export default DispatchFormWrapper;
