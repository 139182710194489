
import React from "react";
import ImgHeroSection from "./ImgHeroSection";
import heroImg from '../assets/images/section.webp';
import './shared/StaticPages.scss';

const PrivacyPolicy = () => {

  return (
    <div>
      <ImgHeroSection src={heroImg} heading="Privacy Policy"/>
      <div className='relative z-30 bg-white'>
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="md:text-4xl text-2xl text-center font-semibold pb-10">Privacy Policy for Quick Dispatch LLC</h2>
          <p className="text-lg text-moderate-orange text-left pb-5">Effective Date: September 23, 2024</p>
          <ol class="list-decimal md:text-xl leading-normal md:text-justify">
            <li>
              <b>Introduction</b><br/>Quick Dispatch LLC (“we,” “us,” “our”) operates the website www.DispatchQuick.com (the “Site”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Site. Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.
            </li>
            <li>
              <b>Information We Collect</b>
              <ul class="list-disc ml-5 xs:ml-1">
                <li>
                  <b>Personal Data:</b> We may collect personally identifiable information, such as your name, shipping address, email address, and telephone number, when you voluntarily provide it to us.
                </li>
                <li><b>Derivative Data:</b> Information our servers automatically collect when you access the Site, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.</li>
                <li><b>Financial Data:</b> Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.</li>
                <li><b>Cookies and Tracking Technologies:</b> We may use cookies and similar tracking technologies to enhance your experience on our</li>
              </ul>
            </li>
            <li>
              <b>Use of Your Information</b><br/>We may use the information we collect about you in the following ways:Use of Your Information We may use the information we collect about you in the following ways:
              <ul class="list-disc ml-5 xs:ml-1">
                <li>
                  To provide, operate, and maintain our Site.
                </li>
                <li>
                  To improve, personalize, and expand our Site.
                </li>
                <li>
                  To understand and analyze how you use our Site.
                </li>
                <li>
                  To develop new products, services, features, and functionality.
                </li>
                <li>
                  To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes.
                </li>
                <li>To process your transactions and manage your orders.</li>
                <li>To send you emails.</li>
                <li>To find and prevent fraud.</li>
              </ul>
            </li>
            <li>
              <b>Data Retention</b><br/>
              We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy or as required by law.
            </li>
            <li>
              <b>Disclosure of Your Information</b><br/>
              We may share information we have collected about you in certain situations:
              <ul class="list-disc ml-5 xs:ml-1">
                <li>
                  <b>By Law or to Protect Rights:</b> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
                </li>
                <li>
                  <b>Third-Party Service Providers:</b> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                </li>
              </ul>
            </li>
            <li>
              <b>Security of Your Information</b><br/>
              We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </li>
            <li>
              <b>Policy for Children</b><br/>
              We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.
            </li>
            <li>
              <b>Changes to This Privacy Policy</b><br/>
              We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of significant changes by posting the new policy on the Site.
            </li>
            <li>
              <b>Contact Us</b><br/>
              If you have questions or comments about this Privacy Policy, please contact us at:
              <ul class="list-disc ml-5 xs:ml-1">
                <li className="mb-2">
                  <a href="tel:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Call: (281) 790-9452</a>
                </li>
                <li>
                  <a href="mailto:info@dispatchquick.com" className="text-moderate-orange hover:text-light-moderate-orange">Email: info@dispatchquick.com</a>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
