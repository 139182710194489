import React from 'react';
import ProfilePage from '../components/ProfilePage';
import Profile from '../components/Profile';
import Equipment from '../components/Equipment';
import Load from '../components/Load';
import DocumentManager from '../components/Document';
import Driver from '../components/Driver';
import Notes from '../components/profile/Notes';
import Company from '../components/profile/Company';
import Agreements from '../components/profile/Agreements';

const ProfilePageRoutes = [
  {
    path: '/profile/:id/',
    element: <ProfilePage />,
    children: [
      {
        path: '',
        element: <Profile />
      },
      {
        path: 'equipment',
        element: <Equipment />
      },
      {
        path: 'loads',
        element: <Load />
      },
      {
        path: 'documents',
        element: <DocumentManager />
      },
      {
        path: 'drivers',
        element: <Driver />
      },
      {
        path: 'notes',
        element: <Notes />
      },
      {
        path: 'company',
        element: <Company />
      },
      {
        path: 'agreements',
        element: <Agreements />
      },
    ]
  },
];

export default ProfilePageRoutes;
