import http from '../../common/http'
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BtnTransparent } from '../shared/Button';
import './modal.scss';

export default function DriverModal({ visible, onHide, driverData, onSave, isLoading }) {
  const initialFormData = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    truck_id: null,
    license_number: '',
  };

  const initialErrorState = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    truck_id: '',
    license_number: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrorState);
  const [trucks, setTrucks] = useState([]);

  useEffect(() => {
    if (visible) {
      fetchTrucks();
    }
  }, [visible]);

  const fetchTrucks = async () => {
    http
      .get('/api/v1/equipments')
      .then((res) => {
        setTrucks(res.data.trucks);
      })
      .catch((err) => {
        console.error('Error fetching trucks:', err);
      });
  };

  useEffect(() => {
    if (driverData) {
      const filteredData = Object.keys(initialFormData).reduce((acc, key) => {
        acc[key] = driverData[key] || '';
        return acc;
      }, {});

      setFormData(filteredData);
    } else {
      setFormData(initialFormData);
    }
    setErrors(initialErrorState);
  }, [driverData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleTruckChange = (e) => {
    setFormData({ ...formData, truck_id: e.value });
    setErrors({ ...errors, truck_id: '' });
  };

  const handleSubmit = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== 'truck_id' && !formData[key]) {
        newErrors[key] = `${key.replace(/_/g, ' ')} is required`;
      }
    });

    if (Object.keys(newErrors).length === 0) {
      onSave(formData);
      setFormData(initialFormData);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Dialog
      header="Driver details"
      headerClassName="text-white"
      visible={visible}
      onHide={onHide}
      className="rounded-lg custom-content w-1/2"
    >
      <div>
        {Object.keys(formData).map((key) => (
          key !== "truck_id" && (
            <div key={key} className="mb-3 space-y-3">
              <label htmlFor={key} className="text-white">
                {key.replace(/_/g, ' ').toUpperCase()}
              </label>
              <InputText
                id={key}
                name={key}
                className={`rounded-lg border border-gray-300 p-2 w-full ${
                  errors[key] ? 'p-invalid' : ''
                }`}
                value={formData[key]}
                onChange={handleChange}
                placeholder={`Enter ${key.replace(/_/g, ' ')}`}
              />
              {errors[key] && (
                <small className="p-error">{errors[key]}</small>
              )}
            </div>
          )
        ))}

        <div className="mb-3 space-y-3">
          <label htmlFor="truck_id" className="text-white">Select Truck</label>
          <Dropdown
            id="truck_id"
            name="truck_id"
            value={formData.truck_id}
            options={trucks.map((truck) => ({
              label: `${truck.truck_no}`,
              value: truck.id,
            }))}
            onChange={handleTruckChange}
            placeholder="Select a truck"
            className={`w-full ${errors.truck_id ? 'p-invalid' : ''}`}
          />
          {errors.truck_id && <small className="p-error">{errors.truck_id}</small>}
        </div>
        <BtnTransparent
          name={driverData?.id ? 'Update' : 'Save'}
          handleClick={handleSubmit}
          className="float-right mt-6"
          isSubmitting={isLoading}
        />
      </div>
    </Dialog>
  );
}
