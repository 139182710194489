import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export const BtnTransparent = ({ name, handleClick, forwardRef, className, disabled = false, isSubmitting = false }) => {
  return (
    <button
      ref={forwardRef}
      type="button"
      className={`${isSubmitting ? 'cursor-not-allowed disabled-color' : ''} rounded-full border-moderate-orange bg-moderate-orange text-white p-2 px-6 hover:bg-light-moderate-orange flex items-center justify-center ${className}`}
      disabled={isSubmitting}
      onClick={handleClick}
    >
      {isSubmitting ? (
        <div className="flex items-center justify-center">
          <ProgressSpinner
            className="w-6 h-6" strokeWidth="8" />
        </div>
      ) : (
        name
      )}
    </button>
  );
};


export const BtnTabs = ({ props, handleViewType }) => {
  return (
    <button
      type='button'
      className={ `${props.text} ${props.bg} ${props.border} ${props.rounded} border-2 w-32 px-2 py-2` }
      onClick={ handleViewType }
    >
      { props.buttonName }
    </button>
  );
};