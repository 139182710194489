import React, { useState, useRef, useEffect } from 'react';
import { BtnTransparent } from '../shared/Button';
import http from '../../common/http';
import { Toast } from 'primereact/toast';
import { isDriver } from "../../utils/helper/role";

const Company = () => {
  const toast = useRef(null);
  const [companyData, setCompanyData] = useState({ phone_number: '' });

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const updateCompany = () => {
    http
      .put(`/api/v1/companies/${companyData.id}`, { company: companyData })
      .then(() => {
        showMessage('success', 'Success', 'Company updated successfully.', 'bg-teal-100 text-teal-500');
      })
      .catch((err) => {
        showMessage('error', 'Error', `Sorry, there was an error while updating your company. ${err.response?.data?.message}`, 'bg-red-100 text-red-700');
      });
  };
  const fetchCompanyInfo = () => {
    http
      .get(`/api/v1/companies`)
      .then((res) => {
        setCompanyData(res.data.company);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching company data.', 'bg-red-100 text-red-700');
      });
  };
  const handleInput = (fieldName, newValue) => {
    setCompanyData((prevCompanyData) => ({
      ...prevCompanyData,
      [fieldName]: newValue,
    }));
  };
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };
  
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <div className='flex w-full flex-col'>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
        <div className='text-2xl font-normal mb-8 font-playfair'>Company</div>
        {!isMobile && !isDriver() && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            className='text-white w-full h-full'
            name='Save changes'
            handleClick={() => {updateCompany()}}
          />
        </div>}
      </div>
      <div>
        <div className='flex w-full justify-between lg:items-start items-center lg:flex-row flex-col'>
          <div className='flex w-full justify-between flex-col lg:w-3/4'>
            <div className='flex w-full flex-col' >
              <div className='text-base mb-2 font-bold font-monsterrat'>
                General Information
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Name
                  </label>
                  <input
                    type="text"
                    key='name'
                    value={companyData.name}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('name', e.target.value) } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    MC
                  </label>
                  <input
                    type="text"
                    key='mc'
                    value={companyData.mc}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('mc', e.target.value) } }
                  />
                </div>
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    US DOT
                  </label>
                  <input
                    type="text"
                    key='usDot'
                    value={companyData.us_dot}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('us_dot', e.target.value) } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    EIN
                  </label>
                  <input
                    type="text"
                    key='ein'
                    value={companyData.ein}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('ein', e.target.value) } }
                  />
                </div>
              </div>
              
              <div className='text-base mt-10 font-bold font-monsterrat'>
                Contact Information
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Email
                  </label>
                  <input
                    type="text"
                    key='email'
                    value={companyData.email}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('email', e.target.value) } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Phone number
                  </label>
                  <input
                    type="text"
                    key='phoneNumber'
                    value={companyData.phone_number}
                    className={`form-control-wrapper mt-1 background w-full border-2 border-moderate-orange p-2 rounded-full pl-4`}
                    onChange={(e) => { handleInput('phone_number', e.target.value) } }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && !isDriver() && (
        <div className='w-full h-[50px] fixed bottom-0 left-0'>
          <BtnTransparent
            className='text-white font-semibold w-full h-full'
            name='Save changes'
            handleClick={() => {updateCompany()}}
          />
        </div>
      )}
    </div>
  );
};

export default Company;
