import React, { useState, useEffect } from 'react';
import http from '../common/http';
import DocumentGrid from './GridView';
import DocumentModal from './modals/DocumentModal';
import DeleteConfirmationModal from './DeletionModal';
import { BtnTransparent } from './shared/Button';
import { useToast } from '../contexts/ToastContext';
import { isDriver } from '../utils/helper/role';

const DocumentManager = () => {
  const [documents, setDocuments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = () => {
    http
      .get('/api/v1/documents')
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((error) => {
        showToast('error', `Failed to fetch documents. ${error.response?.data?.message}`);
      });
  };

  const handleSaveDocument = (doc) => {
    setIsLoading(true);
    if (selectedDocument) {
     
      http
        .put(`/api/v1/documents/${selectedDocument.id}`, doc)
        .then(() => {
          showToast('success', 'Document updated successfully');
          handleCloseModal();
          fetchDocuments();
        })
        .catch((error) => {
          showToast('error', `Failed to update document. ${error.response?.data?.message}`);
          setIsLoading(false);
        });
    } else {
     
      http
        .post('/api/v1/documents', doc)
        .then(() => {
          showToast('success', 'Document created successfully');
          handleCloseModal();
          fetchDocuments();
        })
        .catch((error) => {
          showToast('error', `Failed to create document. ${error.response?.data?.message}`);
          setIsLoading(false);
        });
    }
  };

  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
    setIsModalVisible(true);
  };

  const handleAddDocumentClick = () => {
    setSelectedDocument(null);
    setIsLoading(false);
    setIsModalVisible(true);
  };

  const handleDeleteClick = (doc) => {
    setSelectedDocument(doc);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    if (selectedDocument) {
      http
        .delete(`/api/v1/documents/${selectedDocument.id}`)
        .then(() => {
          showToast('success', 'Document deleted successfully');
          fetchDocuments();
          setIsDeleteModalVisible(false);
          setSelectedDocument(null);
        })
        .catch((error) => {
          showToast('error', `Failed to delete document. ${error.response?.data?.message}`);
        });
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedDocument(null);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Documents</h2>
        { !isDriver() && <BtnTransparent name="Add Document" handleClick={handleAddDocumentClick} /> }
      </div>
      <DocumentGrid
        readOnly={isDriver()}
        documents={documents}
        onDelete={handleDeleteClick}
        onDocumentClick={handleDocumentClick}
      />
      { isModalVisible && <DocumentModal
        visible={isModalVisible}
        onHide={handleCloseModal}
        document={selectedDocument}
        onSave={handleSaveDocument}
        isLoading={isLoading}
      />}
      <DeleteConfirmationModal
        visible={isDeleteModalVisible}
        onHide={() => setIsDeleteModalVisible(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default DocumentManager;
