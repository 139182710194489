import React from "react";
import ImgHeroSection from "./ImgHeroSection";
import heroImg from '../assets/images/section.webp';
import Testimonial from "./testimonial/Testimonial";

const AboutUs = () => {
  return (
    <div>
      <ImgHeroSection src={heroImg} heading="About Us"/>
      <div className='relative z-30 bg-white' id="leadForm">
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="text-2xl md:text-4xl text-center font-semibold pb-5 md:pb-10">
            Welcome to Quick Dispatch, your personal Co-Pilot mile after mile
          </h2>
          <p className="text-center text-xl mb-5">
            With decades of dedicated experience, we specialize in supporting owner-operators by understanding and addressing the unique challenges of the trucking industry. Our mission is simple: KEEP your TRUCK MOVING and your BUISNESS THRIVING!
          </p>
          <p className="text-center text-xl mb-5">
            At  Quick Dispatch, we handle all the logistics and dispatching, so you can stay focused on what matters most to you- DRIVING. Leveraging our deep industry expertise, we seamlessly coordinate our dedicated teams staying in contact with brokers, customers, shippers, ensuring a smooth and efficient experience for you.
          </p>
          <p className="text-center text-xl">
            Let us take care of the details while you keep your wheels on the road
          </p>
        </div>
      </div>
      <Testimonial />
    </div>
  );
};

export default AboutUs;
