import http from '../common/http';
import { useState, useEffect } from "react";
import LoadModal from "./modals/LoadModal";
import DataTable from "./table-view/TableVIew";
import { useToast } from '../contexts/ToastContext';
import { BtnTransparent } from './shared/Button';
import { Dialog } from 'primereact/dialog';
import { isDriver, isSuperAdmin } from "../utils/helper/role";

const Load = () => {
  const [loads, setLoads] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [drivers, setdrivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();

  const headers = [
    { field: "load_number", header: "Load Number" },
    { field: "pickup_location", header: "Pickup Location" },
    { field: "dropoff_location", header: "Dropoff Location" },
   
  ];

  if (!isDriver()) {
    headers.push(
      { field: "total_amount", header: "Amount" },
      { field: "gross_pay", header: "Gross Pay" },
      { field: "percentage", header: "Percentage" },
    );
  }

  if (isDriver()) {
    headers.push(
      { field: "pickup_date", header: "Pickup Date" },
      { field: "dropoff_date", header: "Dropoff Date" },
    );
  }

  useEffect(() => {
    fetchLoadsData();
    fetchDrivers();

    if (isSuperAdmin()) {
      setReadOnly(false);
    }
  }, []);

  const fetchLoadsData = (searchTerm = null) => {
    const params = {};
  
    if (searchTerm) {
      params.search = searchTerm;
    }
  
    http
      .get('/api/v1/loads', { params })
      .then((res) => {
        setLoads(res.data);
      })
      .catch((error) => {
        showToast('error', `Failed to fetch loads data. ${error.response?.data?.message}`);
      });
  };

  const fetchDrivers = () => {
    http
      .get('/api/v1/fetch_drivers')
      .then((res) => {
        setdrivers(res.data.drivers);
      })
      .catch((error) => {
        showToast('error', `Failed to fetch drivers. ${error.response?.data?.message}`);
      });
  };

  const handleModal = (load = null) => {
    setSelectedLoad(load);
    setIsLoading(false);
    setModalVisible(true);
  };

  const handleSave = (loadData) => {
    setIsLoading(true)
    if (selectedLoad) {
      http
        .put(`/api/v1/loads/${selectedLoad.id}`, loadData)
        .then(() => {
          setModalVisible(false);
          fetchLoadsData();
          showToast('success', 'Load updated successfully');
          setSelectedLoad(null);
          return true;
        })
        .catch(() => {
          showToast('error', 'Failed to update load');
          setIsLoading(false);
          return false;
        });
    } else {
      http
        .post('/api/v1/loads', loadData)
        .then(() => {
          setModalVisible(false);
          fetchLoadsData();
          setSelectedLoad(null);
          showToast('success', 'Load added successfully');
          return true;
        })
        .catch((err) => {
          if (err.status === 401) {
            showToast('error', "You don't have permission to add load. Please contact the administrator.");
            setIsLoading(false);
          } else {
            showToast('error', 'Failed to add load');
            setIsLoading(false);
            return false;
          }
        });
    }
  };

  const handleSearch = (searchTerm) => {
    fetchLoadsData(searchTerm);
  }

  const handleDelete = () => {
    if (selectedLoad) {
      setDeleteModalVisible(false);

      http
        .delete(`/api/v1/loads/${selectedLoad.id}`)
        .then(() => {
          fetchLoadsData();
          showToast('success', 'Load deleted successfully');
        })
        .catch(() => {
          showToast('error', 'Failed to delete load');
        });
    }
  };

  return (
    <div>
      <DataTable
        headers={headers}
        data={loads}
        buttonName="Add Load"
        readOnly={readOnly}
        handleClick={() => handleModal()}
        onEdit={handleModal}
        onDelete={(load) => {
          setSelectedLoad(load);
          setDeleteModalVisible(true);
        }}
        onSearch={handleSearch}
      />
      <LoadModal
        visible={modalVisible}
        drivers={drivers}
        onHide={() => setModalVisible(false)}
        loadData={selectedLoad}
        onSave={handleSave}
        isLoading={isLoading}
      />
      <Dialog
        header="Confirm Delete"
        visible={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        headerClassName="text-white"
        footer={
          <div className="flex justify-end space-x-5">
            <BtnTransparent name="Cancel" handleClick={() => setDeleteModalVisible(false)} />
            <BtnTransparent name="Delete" handleClick={handleDelete} />
          </div>
        }
      >
        <p className="text-white">Are you sure you want to delete this Load?</p>
      </Dialog>
    </div>
  );
};

export default Load;
