import React, { useState, useEffect } from "react";
import hero from "../assets/videos/hero.mp4";
import { BtnTransparent } from "./shared/Button";
import { useDispatchForm } from "../contexts/DispatchFormProvider";
import { useInView } from 'react-intersection-observer';
import { DispatchForm } from "./dispatch-form/DispatchForm";

const HeroSection = () => {
  const { isDispatchFormVisible, showDispatchForm, hideDispatchForm } = useDispatchForm();
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
  const { ref: descriptionRef } = useInView({ triggerOnce: true });
  const { ref: buttonRef } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (headerInView) {
      setIsHeaderVisible(true);
      setTimeout(() => {
        setIsDescriptionVisible(true);
      }, 700);
      setTimeout(() => {
        setButtonVisible(true);
      }, 1000);
    }
  }, [headerInView]);

  return (
    <section className="relative h-screen overflow-hidden flex items-center justify-center bg-section">
      <video
        autoPlay
        loop
        muted
        playsInline
        className={`absolute top-0 left-0 -scale-x-100 w-full h-full object-cover z-negative transition-opacity duration-700 ${videoLoaded ? 'opacity-100' : 'opacity-0'}`}
        preload="auto"
        onCanPlay={() => setVideoLoaded(true)}
      >
        <source src={hero} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black opacity-30 z-0"></div>
      <div className="relative z-10 flex flex-col items-start justify-center text-left text-white md:px-28 px-9 py-8">
        <div className="mb-10">
          <div
            ref={headerRef}
            className={`transition-transform duration-700 ${
              isHeaderVisible ? 'translate-y-0 opacity-100' : '-translate-y-20 opacity-0'
            }`}
          >
            <h1 className="md:text-5xl text-3xl mb-6">
              Driver Owned, TEXAS Based Company
            </h1>
          </div>
          <div
            ref={descriptionRef}
            className={`transition-transform duration-700 delay-300 ${
              isDescriptionVisible ? 'translate-y-0 opacity-100' : '-translate-y-20 opacity-0'
            }`}
          >
            <p className="text-xl md:w-3/4">
              Having first-hand knowledge being an owner-operator, we will provide reliable, safe, and efficient service to meet your needs.
            </p>
          </div>
        </div>

        <div
          ref={buttonRef}
          className={`transition-transform duration-700 delay-300 ${
            isButtonVisible ? 'translate-y-0 opacity-100' : '-translate-y-20 opacity-0'
          }`}
        >
          <BtnTransparent
            className="text-xl px-12 py-3"
            name="Start Dispatching Now"
            handleClick={showDispatchForm}
          />
        </div>
      </div>

      {isDispatchFormVisible && (
        <DispatchForm
          isViewDialog={isDispatchFormVisible}
          handleModal={hideDispatchForm}
        />
      )}
    </section>
  );
};

export default HeroSection;
