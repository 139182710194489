import React from 'react';
import Admin from '../components/admin';

const AdminPageRoutes = [
  {
    path: '/admins',
    element: <Admin />,
    children: [ ]
  },
];

export default AdminPageRoutes;
