import http from '../common/http';
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BtnTransparent } from './shared/Button';

const SetPassword = () => {
  const { type, token } = useParams(); // Use the token from the URL
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    const params = {
      user: {
        password: password,
        password_confirmation: confirmPassword,
        token: token,
      }
    }
    if (type === "user") {
      resetUserPassword(params);
    } else {
      resetDriverPassword(params);
    }
  };


  const resetUserPassword = (params) => {
    http
      .patch('/api/v1/users/reset_password', params)
      .then ((res) => {
        setSuccessMessage(res.data.message);
        setErrorMessage("");
        navigate(`/profile/${res.data.user_id}`);
      })
      .catch ((error) => {
        setErrorMessage(error.response?.data?.error || "An error occurred. Please try again.");
        setSuccessMessage("");
      });
  }
  const resetDriverPassword = (params) => {
    http
      .patch('/api/v1/drivers/password/update', params)
      .then ((res) => {
        setSuccessMessage(res.data.message);
        setErrorMessage("");
        navigate(`/profile/${res.data.user_id}`);
      })
      .catch ((error) => {
        setErrorMessage(error.response?.data?.error || "An error occurred. Please try again.");
        setSuccessMessage("");
      });
  };

  return (
    <div className="flex justify-center items-center py-5 bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
        <h1 className="text-2xl font-semibold text-center mb-6  ">Set Your Password</h1>
        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-500 text-center">{successMessage}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <BtnTransparent type="submit" name="Set Password" handleClick={handleSubmit} />
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
