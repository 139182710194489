import http from '../common/http';
import React, { useState, useEffect } from 'react';
import DataTable from "./table-view/TableVIew";
import TruckModal from './modals/TruckModal';
import TrailerModal from './modals/TrailerModal';
import { useToast } from '../contexts/ToastContext';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './shared/Button';
import { TabView, TabPanel } from 'primereact/tabview';

const Equipment = () => {
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [drivers, setdrivers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [trailerModalVisible, setTrailerModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    fetchEquipments();
    fetchDrivers();
  }, []);

  const truckHeaders = [
    { field: "truck_no", header: "Truck Number" },
    { field: "vin_no", header: "Vin No" },
    { field: "license_plate", header: "License Plate" },
    { field: "dimensions", header: "Dimensions" },
    { field: "truck_type", header: "Type" },
    { field: "driver_name", header: "Driver" },
  ];

  const trailerHeaders = [
    { field: "trailer_no", header: "Trailer Number" },
    { field: "vin_no", header: "Vin No" },
    { field: "trailer_type", header: "Type" },
    { field: "driver_name", header: "Driver" },
    { field: "truck", header: "Truck" },
  ];

  const headers = () => {
    return activeIndex === 0 ? truckHeaders : trailerHeaders;
  };

  const tableData = () => {
    return activeIndex === 0 ? trucks : trailers;
  };

  const TRUCK_TYPE_MAPPING = {
    'Flatbed': 'flatbed',
    'Dry Van': 'dry_van',
    'Refrigerated': 'refrigerated',
    'Tanker': 'tanker',
    'Car Carrier': 'car_carrier',
    'Other': 'other',
  };

  const TRIALER_TYPE_MAPPING = {
    'Flatbed': 'flatbed',
    'Step Deck': 'step_deck',
    'RGN': 'rgn',
    'Reefer': 'reefer',
    'Dry Van': 'dry_van',
    'Hotshot': 'hotshot',
    'Box Truck': 'box_truck',
  };

  const fetchEquipments = (searchTerm = null) => {
    const params = {};
  
    if (searchTerm) {
      params.search = searchTerm;
    }
  
    http
      .get('/api/v1/equipments', { params })
      .then((res) => {
        setTrucks(res.data.trucks);
        setTrailers(res.data.trailers);
      })
      .catch((error) => {
        showToast('error', `Failed to fetch equipments data. ${error.response?.data?.message}`);
      });
  };

  const fetchDrivers = () => {
    http
      .get('/api/v1/fetch_drivers')
      .then((res) => {
        setdrivers(res.data.drivers);
      })
      .catch((error) => {
        showToast('error', `Failed to fetch drivers. ${error.response?.data?.message}`);
      });
  };

  const handleEditModal = (obj) => {
    if (obj.trailer_type) {
      handleTrailerModal(obj);
    } else {
      handleModal(obj);
    }
  };

  const handleModal = (obj = null) => {
    setSelectedEquipment(obj);
    setIsLoading(false);
    setModalVisible(true);
  };

  const handleTrailerModal = (obj = null) => {
    setSelectedEquipment(obj);
    setTrailerModalVisible(true);
  };

  const handleSave = (obj) => {
    setIsLoading(true)
    if (obj.truck_type && TRUCK_TYPE_MAPPING[obj.truck_type]) {
      obj.truck_type = TRUCK_TYPE_MAPPING[obj.truck_type];
    }

    if (obj.trailer_type && TRIALER_TYPE_MAPPING[obj.trailer_type]) {
      obj.trailer_type = TRIALER_TYPE_MAPPING[obj.trailer_type];
    }

    if (obj.id) {
      http.put(`/api/v1/equipments/${obj.id}`, obj)
        .then((res) => {
          setTrucks(res.data.trucks);
          setTrailers(res.data.trailers);
          showToast('success', 'Equipment updated successfully');
          setModalVisible(false);
          setTrailerModalVisible(false);
          return true;
        })
        .catch(() => {
          showToast('error', 'Failed to update equipment');
          setIsLoading(false);
          return false;
        });
    } else {
      http.post('/api/v1/equipments', obj)
        .then((res) => {
          setTrucks(res.data.trucks);
          setTrailers(res.data.trailers);
          showToast('success', 'Equipment added successfully');
          setModalVisible(false);
          setTrailerModalVisible(false);
          return true;
        })
        .catch(() => {
          showToast('error', 'Failed to add equipment');
          setIsLoading(false);
          return false;
        });
    }
  };

  const handleDelete = () => {
    if (selectedEquipment) {
      http
        .delete(`/api/v1/equipments/${selectedEquipment.id}`, { params: selectedEquipment })
        .then((res) => {
          setTrucks(res.data.trucks);
          setTrailers(res.data.trailers);
          showToast('success', 'Equipment deleted successfully');
          setDeleteModalVisible(false);
        })
        .catch(() => {
          showToast('error', 'Failed to delete equipment');
        });
    }
  };

  const handleSearch = (searchTerm) => {
    fetchEquipments(searchTerm);
  }

  return (
    <div>
      <TabView
        className="equipments-tabs"
        activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Trucks"></TabPanel>
        <TabPanel header="Trailers"></TabPanel>
      </TabView>
      <DataTable
        headers={headers()}
        data={tableData()}
        buttonName="Add Truck"
        handleClick={() => handleModal()}
        SecButtonName="Add Trailer"
        SecHandleClick={() => handleTrailerModal()}
        onEdit={handleEditModal}
        onDelete={(truck) => {
          setSelectedEquipment(truck);
          setDeleteModalVisible(true);
        }}
        onSearch={handleSearch}
      />
      <TruckModal
        visible={modalVisible}
        onHide={() => setModalVisible(false)}
        truckData={selectedEquipment}
        drivers={drivers}
        onSave={handleSave}
        isLoading={isLoading}
      />
      <TrailerModal
        visible={trailerModalVisible}
        onHide={() => setTrailerModalVisible(false)}
        trailerData={selectedEquipment}
        drivers={drivers}
        trucks={trucks}
        onSave={handleSave}
      />
      <Dialog
        header="Confirm Delete"
        visible={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        headerClassName="bg-red-600 text-white"
        style={{ width: '90vw', maxWidth: '400px' }}
        footer={
          <div className="flex justify-end space-x-4">
            <BtnTransparent name="Cancel" handleClick={() => setDeleteModalVisible(false)} />
            <BtnTransparent name="Delete" handleClick={handleDelete} />
          </div>
        }
      >
        <p className="text-white">Are you sure you want to delete this equipment?</p>
      </Dialog>
    </div>
  );
};

export default Equipment;
