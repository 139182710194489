import React, { useState, useEffect, useRef } from 'react';
import { Menubar } from 'primereact/menubar';
import logo from '../../assets/images/QuickDispatch-logo.png';
import { BtnTransparent } from '../shared/Button';
import './Header.scss';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatchForm } from '../../contexts/DispatchFormProvider';
import { DispatchForm } from '../dispatch-form/DispatchForm';
import TranslateComponent from "./../TranslateComponent";
import SignInForm from '../auth/sign-in/SignIn';
import SignUp from '../auth/sign-up/SignUp';
import { isLoggedIn, logOut, getUserFullName } from '../../common/auth';
import { ourServiceTypes } from "../Services";
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { getUserId } from '../../common/auth';
import { isSuperAdmin, isDriver } from "../../utils/helper/role";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isDispatchFormVisible, showDispatchForm, hideDispatchForm } = useDispatchForm();
  const [isSignInFormVisible, setIsSignInFormVisible] = useState(false);
  const [isSignUpFormVisible, setIsSignUpFormVisible] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [fullName, setFullName] = useState('');
  const navigate = useNavigate();
  const location = useLocation()
  const initials = fullName ? fullName[0] : '';
  const userId = getUserId();
  const menuRef = useRef(null);
  const authenticated = isLoggedIn();


  useEffect(() => {
    if (authenticated) {
      setFullName(getUserFullName());
    }
  }, [authenticated]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('referral_code');
    if (location.pathname === '/sign-up' && code) {
      setIsSignUpFormVisible(true);
    }
  }, [location]);

  const servicesSubMenuItems = ourServiceTypes.map((service) => {
    return {...service, command: () => navigate(service.link), link: service.link };
  });

  const items = [
    !authenticated && mobileMenuOpen ? {
      label: 'Sign In',
      command: () => setIsSignInFormVisible(true),
      link: '/sign-in',
    } : null,
    !authenticated && mobileMenuOpen ? {
      label: 'Sign Up',
      command: () => setIsSignUpFormVisible(true),
      link: '/sign-up',
    } : null,
    {
      label: 'Get Started',
      command: () => navigate('/get-started#leadForm'),
      link: '/get-started#leadForm',
    },
    {
      label: 'Our Services',
      command: () => navigate('/services'),
      link: '/services',
      items: servicesSubMenuItems,
    },
    {
      label: 'About Us',
      command: () => navigate('/about-us'),
      link: '/about-us',
    },
  ].filter(Boolean);

  const navigateMenu = (path) => {
    setVisibleMenu(false);
    navigate(path);
  }

  let menuItems = [
    { label: 'Profile', icon: 'pi pi-user', command: () => navigateMenu(`/profile/${userId}`) },
    { label: 'Company', icon: 'pi pi-truck', command: () => navigateMenu(`/profile/${userId}/company`) },
    { label: 'Equipment', icon: 'pi pi-truck', command: () => navigateMenu(`/profile/${userId}/equipment`) },
    { label: 'Load History', icon: 'pi pi-box', command: () => navigateMenu(`/profile/${userId}/loads`) },
    { label: 'Drivers', icon: 'pi pi-box', command: () => navigateMenu(`/profile/${userId}/drivers`) },
    { label: 'Documents', icon: 'pi pi-box', command: () => navigateMenu(`/profile/${userId}/documents`) },
  ];

  if (isSuperAdmin()) {
    menuItems.push({ label: 'Notes', icon: 'pi pi-box', command: () => navigateMenu(`/profile/${userId}/notes`) });
  }

  if (isSuperAdmin() || !isDriver()) {
    menuItems.push({ label: 'Agreements', icon: 'pi pi-box', command: () => navigateMenu(`/profile/${userId}/agreements`) });
  }

  menuItems.push({ label: 'Logout', icon: 'pi pi-sign-out', command: () => handleLogout() })

  const handleNavigation = () => {
    showDispatchForm();
  };

  const openSignupForm = () => {
    setIsSignInFormVisible(false);
    setIsSignUpFormVisible(true);
  };

  const openSignInForm = () => {
    setIsSignUpFormVisible(false);
    setIsSignInFormVisible(true);
  };

  const handleLogout = () => {
    logOut();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setVisibleMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleCloseSignUp = () => {
    setIsSignUpFormVisible(false);
    navigate('/');
  };

  return (
    <header className="bg-[#161616] text-white shadow-md sticky w-full top-0 z-50">
      <div className="md:container mx-auto p-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Link onClick={ () => navigate('/') }>
              <img src={logo} alt="Quick Dispatch" className="md:h-24 xs:h-14" />
            </Link>
            <span className="text-xl font-bold"></span>
          </div>

          <div className="hidden md:flex flex-grow">
            <Menubar model={items} className="custom-menubar text-lg" />
          </div>
          
          {!authenticated && <div className="px-2 text-xl hidden md:block">
            <span
              className="px-2 cursor-pointer"
              onClick={() => setIsSignInFormVisible(true)}
            >
              Sign In
            </span>
            {/* <span
              className="px-2 cursor-pointer"
              onClick={() => setIsSignUpFormVisible(true)}
            >
              Sign Up
            </span> */}
          </div>}

          <TranslateComponent className="mr-2" />
          {!isSuperAdmin() && (
            <div className='flex'>
              <div className='hidden md:block md:pr-5'>
                <BtnTransparent
                  className="md:text-lg"
                  name='Contact Us'
                  handleClick={ handleNavigation }
                />
              </div>
              <div className="hidden md:flex items-center space-x-4 text-lg">
                <>
                  <a href="tel:2817909452" className="text-white underline-offset-2">(281) 790-9452</a>
                </>
              </div>
            </div>
          )}
          {isSuperAdmin() && (
            <Link to="/admins">
              Admin Panel
            </Link>
          )}
          <div className='pl-5'>
            {authenticated && (
              <Avatar
                label={initials}
                className="p-overlay-badge cursor-pointer bg-moderate-orange text-white"
                shape="circle"
                size="large"
                onClick={() => setVisibleMenu(true)}
              />
            )}
          
            {visibleMenu && (
              <div ref={menuRef} className="absolute top-24 right-0 z-10 shadow-lg text-black rounded-md">
                <Menu model={menuItems} className="text-black" />
              </div>
            )}
      
          </div>
          <button
            className="md:hidden flex items-cente p-2"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <i className={`pi ${mobileMenuOpen ? 'pi-times' : 'pi-bars'} text-2xl`}></i>
          </button>
        </div>

        {mobileMenuOpen && (
          <div className="md:hidden bg-moderate-orange p-4">
            {items.map((menu, idx) => (
              <div className="mt-4" key={idx}>
                <p onClick={() => { navigate(menu.link); setMobileMenuOpen(!mobileMenuOpen);}}>{menu.label}</p>
              </div>
            ))}
            <div className="mt-4">
              <p onClick={handleNavigation}>Contact Us</p>
            </div>
            <div className="mt-4">
              <a href="tel:2817909452">(281) 790-9452</a>
            </div>
          </div>
        )}
      </div>

      <DispatchForm
        isViewDialog={isDispatchFormVisible}
        handleModal={hideDispatchForm}
      />

      <SignUp
        isOpen={isSignUpFormVisible}
        onClose={handleCloseSignUp}
        openSignupForm={openSignInForm}
      />

      <SignInForm
        isOpen={isSignInFormVisible}
        onClose={() => setIsSignInFormVisible(false)}
        openSignupForm={openSignupForm}
      />
    </header>
  );
};

export default Header;
