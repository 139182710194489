import React from 'react';
import { FaTrash, FaPencilAlt, FaFileAlt, FaFileWord, FaFilePdf, FaFileImage } from 'react-icons/fa'; // Import specific icons

const DocumentGrid = ({ documents, onDelete, onDocumentClick, readOnly }) => {
  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'doc':
      case 'docx':
        return <FaFileWord size={40} className="text-blue-500" />;
      case 'pdf':
        return <FaFilePdf size={40} className="text-red-500" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FaFileImage size={40} className="text-yellow-500" />;
      default:
        return <FaFileAlt size={40} className="text-gray-500" />;
    }
  };

  const getFileType = (filename) => {
    const extension = filename.split('.').pop().toUpperCase();
    const pluralized = extension === 'DOC' || extension === 'DOCX' ? 'Document' : `${extension}`;
    return pluralized;
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {documents.map((doc, index) => (
        <div
          key={index}
          className="bg-white p-4 rounded-lg border-4 shadow relative"
        >
          { !readOnly && <div className="flex absolute right-2">
            <FaPencilAlt
              className="mr-5 cursor-pointer"
              onClick={() => onDocumentClick(doc)}
            />

            <FaTrash
              className="text-red-500 hover:text-red-700 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(doc);
              }}
            />
          </div>}
          
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => window.open(doc.file_url)}
          >
            <div className="mb-2">
              {getFileIcon(doc.file_name)}
            </div>
            <div className="text-center">
              <p>{doc.name}</p>
              <p className="text-gray-500">{getFileType(doc.file_name)}</p>
            </div>
          </div>
          
        </div>
      ))}
    </div>
  );
};

export default DocumentGrid;
