import React, { useState } from 'react';
import SplashScreen from './components/SplashScreen';
import { RouterProvider } from 'react-router-dom';
import BrowserRouter from './routes';
import { ToastProvider } from './contexts/ToastContext';
import DispatchFormWrapper from './wrapper/DispatchFormWrapper';
import { DispatchFormProvider } from './contexts/DispatchFormProvider';

const App = () => {
  const [showSplash, setShowSplash] = useState(true);

  return (
    <DispatchFormProvider>
      <div className="flex flex-col min-h-screen">
        {showSplash ? (
          <SplashScreen onComplete={() => setShowSplash(false)} />
        ) : (
          <>
            <ToastProvider>
              <RouterProvider router={BrowserRouter} />
              <DispatchFormWrapper />
            </ToastProvider>
          </>
        )}

      </div>
    </DispatchFormProvider>
  );
};

export default App;
