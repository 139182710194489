import React from 'react';

const NotFound = () => {
  return (

    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <div>
          <h2 className="text-4xl mb-4 font-semibold">Page Not Found</h2>
          <p className="text-xl">The page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
