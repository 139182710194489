import React from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './shared/Button';

const DeleteConfirmationModal = ({ visible, onHide, onConfirm }) => {
  return (
    <Dialog header="Confirm Deletion" visible={visible} onHide={onHide} headerClassName="text-white">
      <div className="p-4 text-white">
        <p>Are you sure you want to delete this document?</p>
        <div className="flex justify-end space-x-2 mt-4">
          <BtnTransparent name="Cancel" handleClick={onHide} />
          <BtnTransparent name="Delete" handleClick={onConfirm} />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
