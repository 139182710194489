import React, { useState, useRef } from 'react';
import http from '../../../common/http';
import { getUserSession } from '../../../common/auth';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../../shared/Button'; 
import { useToast } from '../../../contexts/ToastContext';
import { emailRegex, passwordRegex, usernameRegex } from '../../../utils/helper/string';
import './SignIn.scss'

const SignInForm = ({ isOpen, onClose, openSignupForm, redirectRoute = '' }) => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const showToast = useToast();

  const handleClose = () => {
    setEmail('');
    setPassword('');
    onClose();
    setShowResetPassword(false);
  };

  if (!isOpen) return null;

  const redBox = "2px solid red";

  const passwordInputChange = () => {
    passwordInput.current.style.border = '';
  };

  const emailInputChange = () => {
    emailInput.current.style.border = '';
  };

  const validateForm = () => {
    if (!email || !password) {
      if (!email) {
        emailInput.current.style.border = redBox;
      }
      if (!password) {
        passwordInput.current.style.border = redBox;
      }
      showToast('error', 'Please fill in all the required fields.');
      return false;
    }
    if (!emailRegex.test(email) && !usernameRegex.test(email)) {
      showToast('error', 'Please enter a valid username or email address.');
      return false;
    }
    if (!passwordRegex.test(password)) {
      showToast('error', 'Password should be at least 8 digits long.');
      return false;
    }
    return true;
  };

  const handleResetPassword = () => {
    if (!email) {
      showToast('error', 'Please enter your email address.');
      return;
    }
    if (!emailRegex.test(email)) {
      showToast('error', 'Please enter a valid email address.');
      return;
    }
    http
      .post('/api/v1/users/forgot_password', { email })
      .then(() => {
        showToast('success', 'Password reset link has been sent to your email.');
        setShowResetPassword(false);
      })
      .catch(() => {
        showToast('error', 'Failed to send reset password email. Please try again.');
      });
  };

  const submit = () => {
    if (validateForm()) {
      const params = {
        user: {
          email: email,
          password: password
        }
      };
      http
        .post('/api/v1/users/sign_in', params)
        .then((res) => {
          if (res.data.user) {
            const user = res.data.user;
            localStorage.setItem('userId', user.id);
            const fullName = `${user.first_name} ${user.last_name}`;
            localStorage.setItem('fullName', fullName);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userRole', user.role);
            localStorage.setItem('currentCompanyId', user.company_id);

            if (user.role === 'super_admin') {
              window.location.href = '/admins';
            } else if (redirectRoute) {
              window.location.href = redirectRoute;
            } else {
              window.location.href = `/profile/${user.id}`;
            }
          } else {
            getUserSession();
          }
          handleClose();
        })
        .catch(() => {
          showToast('error', 'Invalid username or password. Please try again.');
        });
    }
  };

  const footer = () => (
    <div className='relative flex justify-between items-center footer h-24 px-6'>
      <div className='text-white'>
        Don&apos;t have an account?
      </div>
      <div>
        <BtnTransparent
          className='text-white font-semibold bg-[#484c4c] border-[#484c4c]'
          name='Create an account'
          handleClick={openSignupForm}
        />
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <Dialog
      header="Sign in to your account"
      headerClassName="text-2xl font-normal text-white px-6 pt-5 custom-header"
      contentClassName="custom-header"
      visible={true}
      onHide={handleClose}
      style={{ width: '80%', maxWidth: '533px', overflow: 'hidden' }}
      draggable={false}
      resizable={false}
    >
      {!showResetPassword ? (
        <div className='px-6'>
          <div className='mt-8'>
            <label className='text-sm my-4 text-white'>Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailInput}
              className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg"
            />
          </div>
          <div className='mt-4'>
            <label className='text-sm text-white'>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={passwordInput}
              className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg"
            />
          </div>
          <div className='mt-2'>
            <button
              onClick={() => setShowResetPassword(true)}
              className="text-blue-500 text-sm underline float-right py-3"
            >
              Forgot Password?
            </button>
          </div>
          <BtnTransparent
            className='text-white w-full mt-6 font-semibold'
            name='Sign in'
            handleClick={submit}
          />
        </div>
      ) : (
        <div className='px-6'>
          <div className='mt-8'>
            <label className='text-sm my-4 text-white'>Enter your email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg"
            />
          </div>
          <BtnTransparent
            className='text-white w-full mt-6 font-semibold'
            name='Reset Password'
            handleClick={handleResetPassword}
          />
          <button
            onClick={() => setShowResetPassword(false)}
            className="text-blue-500 text-sm underline mt-4"
          >
            Back to Sign In
          </button>
        </div>
      )}
    </Dialog>
  );
};

export default SignInForm;
