import React, { useEffect } from "react";
import ImgHeroSection from "./ImgHeroSection";
import heroImg from '../assets/images/section.webp';
import boxTruckImg from '../assets/images/box-truck.webp';
import drayageTruckImg from '../assets/images/drayage.webp';
import dryvanTruckImg from '../assets/images/dryvan.webp';
import flatbedTruckImg from '../assets/images/flatbed.webp';
import hotshotTruckImg from '../assets/images/hotshot.webp';
import poweronlyTruckImg from '../assets/images/power-only.webp';
import reeferTruckImg from '../assets/images/reefer.webp';
import stepdeckTruckImg from '../assets/images/step-deck.webp';
import { useDispatchForm } from '../contexts/DispatchFormProvider';
import { BtnTransparent } from './shared/Button';
import { useLocation } from 'react-router-dom';

const ourServices = [
  { id: "flatbed", type: "FLATBED", pricing: "7%", discount: "5%", image: flatbedTruckImg, description: "Your flatbed truck is a powerful vehicle that plays a vital role in our economy, tackling jobs that other vehicles simply can’t handle. Whether it’s transporting piping, beams, and frames for construction projects, delivering bulk materials and ballast for landscaping, or moving heavy equipment to various job sites, all these tasks demand strong logistics capabilities. We’re here to be your partner, providing the dispatch services you need to connect your flatbed with clients who will gain the most from your expertise."},
  { id: "stepdeck", type: "STEP DECK / DROP DECK", pricing: "7%", discount: "5%", image: stepdeckTruckImg, description: "When it comes to transporting cargo with step deck or drop deck trailers, it’s all about going big. Handling oversized and over-height freight is a significant responsibility that requires navigating complex logistical challenges to keep your business thriving. If you find yourself spending much of your day searching for and assessing potential step deck loads, negotiating prices and freight rates, completing the necessary paperwork, and ensuring compliance with all relevant regulations and industry standards, it can be difficult to focus on delivering those large loads safely and on schedule."},
  { id: "dryvan", type: "DRY VAN", pricing: "7%", discount: "5%", image: dryvanTruckImg, description: "Dry van transportation is a fundamental component of freight logistics, serving a diverse array of industries and shipping requirements. Whether you’re transporting consumer goods, industrial supplies, or perishables, dry van carriers provide a dependable solution for securing and moving your cargo. This mode of freight transportation boasts several advantages, such as versatility, cost-effectiveness, and straightforward loading and unloading processes, making it a preferred option for many shippers. We eliminate the uncertainty of managing dry van freight, allowing you to concentrate on your core strengths—driving and delivering. Our experienced team works tirelessly to pair loads with the right carriers, ensuring timely and secure deliveries every time."},
  { id: "reefer", type: "REEFER", pricing: "7%", discount: "5%", image: reeferTruckImg, description: "When businesses in the U.S. need temperature-controlled freight, refrigerated trucks—often referred to as reefer trucks—are essential. These vehicles feature advanced technology to ensure optimal conditions for fresh produce, meat, and other sensitive cargo. As you know, your reefer truck already provides outstanding service to your customers. To sustain this level of excellence, you require a reliable dispatcher—someone who understands your business and employs efficient, hassle-free processes to keep your operations on track."},
  { id: "poweronly", type: "POWER ONLY", pricing: "7%", discount: "5%", image: poweronlyTruckImg, description: "Our team streamlines the process of connecting power-only loads with independent owners and truck fleets whenever you need to move equipment on a flatbed trailer, dry van trailer, refrigerated van, shipping container, or tanker trailer. We can find the optimal power-only trucking solutions for any transportation requirement. Power-only trucking services involve using an independent driver or carrier tractor to transport a trailer. This method provides a cost-effective way for brokers to ensure complete logistics for their loads."},
  { id: "boxtrucks", type: "BOX TRUCK", pricing: "10%", discount: "7%", image: boxTruckImg, description: "A box trucking business capitalizes on its versatility and the ability to operate loads with little downtime, all without the necessity of a commercial driver’s license. Achieving success depends on securing the most profitable loads, efficiently coordinating trips, and managing the ongoing paperwork. For many small and medium-sized box truck businesses, this often feels like a never-ending juggling act that fails to provide the expected returns."},
  { id: "hotshot", type: "HOTSHOT", pricing: "7%", discount: "5%", image: hotshotTruckImg, description: "Many people outside the trucking industry are unaware of the challenging work that goes into delivering their equipment, appliances, and materials. At Quick Dispatch, we recognize that hotshot drivers like you are the real “minutemen” of trucking, making sure loads reach their destinations on time. That’s why we offer outstanding hotshot dispatch services designed to enhance your bottom line and support the success of your hotshot trucking business."},
  { id: "drayage", type: "DRAYAGE", pricing: "7%", discount: "5%", image: drayageTruckImg, description: "Drayage is the process of transporting goods over short distances, primarily between shipping ports and adjacent warehouses or distribution centers. This crucial logistical service focuses on the efficient handling and movement of cargo, ensuring that products are swiftly and safely transferred from one mode of transport to another. Drayage plays a vital role in supply chain management, facilitating the seamless flow of goods in urban areas and helping to minimize delays in the shipping process."},
];

export const ourServiceTypes = ourServices.map((service) => {
  return { label: service.type, link: `/services#${service.id}` };
});


const Services = () => {
  const { showDispatchForm } = useDispatchForm();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const top = element.getBoundingClientRect().top + window.scrollY - 200;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <ImgHeroSection src={heroImg} heading="Our Services"/>
      <div className='relative z-30 bg-white' id="leadForm">
        <div className='w-full px-5 md:px-28 py-10'>
          <h2 className="md:text-4xl text-2xl text-center font-semibold pb-10">Services provided by Quick Dispatch LLC</h2>

          <div className="flex flex-col items-center">
            {ourServices.map((service) => (
              <div className="md:flex mb-5 hover:shadow-md shadow-xl rounded-lg md:h-68" id={service.id} key={service.id}>
                <div className="md:w-1/2"><img src={service.image} alt="Truck Type" className="w-full h-full object-cover object-right md:rounded-l-lg xs:rounded-t-lg"/></div>
                <div className="md:w-2/3 flex flex-col justify-between m-10 xs:m-5">
                  <div>
                    <p className="md:text-4xl text-moderate-orange mb-2 xs:text-2xl">{service.type}</p>
                    <p className="md:text-lg">{service.description}</p>
                  </div>
                  <div className="flex items-center md:justify-center md:text-lg xs:text-xs xs:justify-between xs:mt-5">
                    <p className="font-bold text-moderate-orange">Service Fee {service.pricing}</p>
                    <BtnTransparent
                      className="md:text-lg md:ml-5"
                      name={`Call Now & Receive ${service.discount}`}
                      handleClick={showDispatchForm}
                    />
                  </div>
                </div>
              </div>  
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
