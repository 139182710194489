import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SingleSelectDropdown from './shared/SingleSelectDrodown';
import Profile from './Profile';
import Equipment from './Equipment';
import Load from './Load';
import DocumentManager from './Document';
import Notes from './profile/Notes';
import Company from './profile/Company';
import Agreements from './profile/Agreements';
import Driver from './Driver';
import { isLoggedIn } from '../common/auth';
import { isSuperAdmin, isDriver } from "../utils/helper/role";

const ProfilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const scopedUserId = location.pathname.split('/')[2];
  const [isProfileDirty,setIsProfileDirty] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');


  useEffect(() => {
    setOptionFromPath();
  }, []);

  const setOptionFromPath = () => {
    const newValue = selectedUrl(location.pathname.split('/').pop());
    setSelectedOption((newValue).charAt(0).toUpperCase() + newValue.slice(1));
  };

  const onLinkClick = (data) => {
    setSelectedOption(data);
  };

  let options = [
    'Profile',
    'Company',
    'Equipment',
    'Load History',
    'Drivers',
    'Documents',
  ];

  if (isSuperAdmin()) {
    options.push('Notes');
  }

  if (isSuperAdmin() || !isDriver()) {
    options.push('Agreements');
  }

  const selectedUrl = (value) => {
    switch (value.toLowerCase()) {
      case 'profile':
        return '';
      case 'company':
        return 'company';
      case 'equipment':
        return 'equipment';
      case 'loads':
      case 'load':
      case 'load history':
        return 'loads';
      case 'drivers':
      case 'driver':
        return 'drivers';
      case 'documents':
      case 'document':
        return 'documents';
      case 'agreements':
        return 'agreements';
      case 'notes':
        return 'notes';
      default:
        return 'profile';
    }
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  if (!isLoggedIn()) {
    return navigate('/');
  }

  if (location.pathname.includes("notes") && !isSuperAdmin()) {
    return navigate('/');
  }

  return (
    <div>
      <div className='flex w-full bg-moderate-orange z-40'>
        <div className='flex w-full'>
          <div className="flex flex-wrap w-full isolate lg:px-20 px-3 justify-center">
            <div className='flex w-full flex-col flex-wrap'>
            <div className='flex'>
              <p className='text-3xl text-white font-normal py-4'>Your account</p>
            </div>
            {!isMobile && <div className='grid grid-cols-4 w-90% mb-4 card-container shadow-md'>
              <div className='bg-gray-50 p-16'>
                <div className='flex flex-wrap flex-col'>
                  <div className='mb-12'>
                    <Link to={`/profile/${scopedUserId}`} onClick={()=>onLinkClick('Profile')}>
                      <p className={`relative inline cursor-pointer ${[`/profile/${scopedUserId}`, 'Profile'].includes(selectedOption) ? 'text-moderate-orange' : 'font-medium'}`}>Profile</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${scopedUserId}/company`:null} onClick={()=>onLinkClick('Company')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Company') ? 'text-moderate-orange' : 'font-medium'}`}>Company</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${scopedUserId}/equipment`:null} onClick={()=>onLinkClick('Equipment')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Equipment') ? 'text-moderate-orange' : 'font-medium'}`}>Equipment</p>
                    </Link>
                  </div>

                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${scopedUserId}/loads`:null} onClick={()=>onLinkClick('Load')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Load') ? 'text-moderate-orange' : 'font-medium'}`}>Load History</p>
                    </Link>
                  </div>

                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${scopedUserId}/drivers`:null} onClick={()=>onLinkClick('Driver')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Driver') ? 'text-moderate-orange' : 'font-medium'}`}>Driver</p>
                    </Link>
                  </div>

                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${scopedUserId}/documents`:null} onClick={()=>onLinkClick('Document')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Document') ? 'text-moderate-orange' : 'font-medium'}`}>Documents</p>
                    </Link>
                  </div>

                  {(isSuperAdmin()  || !isDriver()) && (
                    <div className='mb-12'>
                      <Link to={!isProfileDirty?`/profile/${scopedUserId}/agreements`:null} onClick={()=>onLinkClick('Agreements')}>
                        <p className={`relative inline cursor-pointer ${selectedOption?.includes('Agreements') ? 'text-moderate-orange' : 'font-medium'}`}>Agreements</p>
                      </Link>
                    </div>
                  )}

                  {isSuperAdmin() && (
                    <div className='mb-12'>
                      <Link to={!isProfileDirty?`/profile/${scopedUserId}/notes`:null} onClick={()=>onLinkClick('Notes')}>
                        <p className={`relative inline cursor-pointer ${selectedOption?.includes('Notes') ? 'text-moderate-orange' : 'font-medium'}`}>Notes</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-span-3 bg-white p-16'>
                {
                  location.pathname.includes("company")?<Company />:
                  location.pathname.includes("equipment")?<Equipment />:
                  location.pathname.includes("load")?<Load />:
                  location.pathname.includes("driver")?<Driver />:
                  location.pathname.includes("document")?<DocumentManager />:
                  location.pathname.includes("agreements")?<Agreements />:
                  location.pathname.includes("notes")?<Notes />:
                  <Profile setIsProfileDirty={setIsProfileDirty}/>
                 
                }
              </div>
            </div>}

            {isMobile && (
              <div className='w-full block pt-3 lg:hidden'>
                <SingleSelectDropdown
                  props={{ label: 'Select an option', labelStyle: 'text-white', options: options, value: selectedOption }}
                  handleChanges={ (e) => {
                    onLinkClick(e.value);
                    navigate(`/profile/${scopedUserId}/${selectedUrl(e.value)}`);
                  }}
                />
                <div className='block sm:flex w-full sm:flex-wrap col-span-3 py-2 px-3 bg-white'>
                  {
                    location.pathname.includes("company")?<Company />:
                    location.pathname.includes("equipment")?<Equipment />:
                    location.pathname.includes("load")?<Load />:
                    location.pathname.includes("driver")?<Driver />:
                    location.pathname.includes("document")?<DocumentManager />:
                    location.pathname.includes("agreements")?<Agreements />:
                    location.pathname.includes("notes")?<Notes />:
                    <Profile setIsProfileDirty={setIsProfileDirty}/>
                  }
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
