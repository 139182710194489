import { Dropdown } from 'primereact/dropdown';
import React, { forwardRef } from 'react';

const SingleSelectDropdown = forwardRef(({ props, handleChanges }, ref) => {

  const customDropdownStyle = {
    paddingRight: '1rem',
  };

  return (
    <div className='mb-1'>
      <label className={`${props?.labelStyle}`}> { props.label } </label>
      <Dropdown
        ref={ ref }
        options={ props.options }
        value={ props.value }
        className={ `flex items-center  px-3 text-sm` }
        style={ props.surveryPage ? customDropdownStyle : '' }
        onChange={ handleChanges }
        itemTemplate={ (option) => (
          <div className={ `flex items-center text-sm cursor-pointer hover:bg-gray-300 ${props.dropDownwidth}` }>
            { option }
          </div>
        )}
      />
    </div>
  );
});

SingleSelectDropdown.displayName = 'SingleSelectDropdown';

export default SingleSelectDropdown;
