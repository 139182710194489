import React from 'react';
import { Carousel } from 'primereact/carousel';
import { useInView } from 'react-intersection-observer';
import './Testimonial.scss';
import { Rating } from 'primereact/rating';
import maleAvatar from "../../assets/images/male-avatar.svg"
import femaleAvatar from "../../assets/images/female-avatar.svg"

const testimonials = [
  {
    name: "Aalim D.",
    text: "“Quick Dispatch LLC has been a game-changer for my trucking business. Their team is always available, day or night, and they negotiate the best rates for my loads. My truck is always moving, and I couldn’t be happier with their service!”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Cheryl U.",
    text: "“The customer service at Quick Dispatch LLC is top-notch. They are multilingual, which makes communication so much easier. I always feel supported and valued as a client.”",
    rate: 4,
    gender: "female",
  },
  {
    name: "- Rameez S.",
    text: "“I’ve been working with Quick Dispatch LLC for years, and their experience really shows. They have a great relationship with drivers and dispatchers, making the whole process smooth and efficient.”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Robin M.",
    text: "“Quick Dispatch LLC keeps my truck moving and my business thriving. Their load booking process is seamless, and I can always reach someone from their team, no matter the time of day.”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Shayan M.",
    text: "“The team at Quick Dispatch LLC is incredibly reliable. They always find the best loads and ensure my truck is never idle. I highly recommend their services!”",
    rate: 4,
    gender: "female",
  },
  {
    name: "- Smith",
    text: "“Quick Dispatch LLC has exceeded my expectations. Their customer service is fantastic, and they are always available when I need them. My truck is constantly on the road, thanks to their efficient dispatching.”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Eddy I.",
    text: "“I appreciate the multilingual support at Quick Dispatch LLC. It makes communication so much easier. They always keep my truck moving and find the best opportunities for my business.”",
    rate: 4,
    gender: "male",
  },
  {
    name: "- Jason H.",
    text: "“Quick Dispatch LLC is the best dispatching company I’ve worked with. Their team is always available, and they have a great relationship with drivers. They keep my truck moving and my business profitable.”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Raul R.",
    text: "“The experience and dedication of the team at Quick Dispatch LLC are unmatched. They always find the best loads and ensure my truck is always on the move. I couldn’t ask for a better dispatching service.”",
    rate: 4,
    gender: "male",
  },
  {
    name: "- Emanual J.",
    text: "“Quick Dispatch LLC has been a reliable partner for my trucking business. Their customer service is excellent, and they are always available to help. They keep my truck moving and my business growing.”",
    rate: 5,
    gender: "male",
  },
  {
    name: "- Eric C.",
    text: "“I’ve had a great experience with Quick Dispatch LLC. Their team is always available, and they secure the best deals for my loads. My truck is always on the road, thanks to their efficient dispatching.”",
    rate: 4,
    gender: "male",
  },
  {
    name: "- Jimmie",
    text: "“Quick Dispatch LLC is a fantastic dispatching company. Their customer service is top-notch, and they always find the best loads for my truck. I highly recommend their services!”",
    rate: 5,
    gender: "male",
  }
];

const Testimonial = () => {
  const { ref: containerRef, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const testimonialTemplate = (testimonial) => {
    return (
      <div
        className={`p-4 bg-white rounded-lg shadow-lg flex flex-col items-center transition-transform transform hover:scale-105 ${
          inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <img
          src={testimonial.gender === "male" ? maleAvatar : femaleAvatar}
          alt={testimonial.name}
          className="rounded-full shadow-md mb-4 w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 transition-transform transform hover:scale-110"
        />
        <Rating value={testimonial.rate} readOnly cancel={false} className="custom-rating my-5"/>
        <p
          className={`text-gray-600 text-sm sm:text-base md:text-lg mb-4 text-center md:w-3/4 lg:w-2/3 transition-opacity duration-500 ${
            inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          {testimonial.text}
        </p>
        <h4
          className={`text-base sm:text-lg md:text-xl font-semibold transition-opacity duration-500 ${
            inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          {testimonial.name}
        </h4>
      </div>
    );
  };

  return (
    <div className="pb-24 pt-6 bg-moderate-orange font-sans relative z-30">
      <h2
        className={`transition-opacity duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} md:text-4xl text-2xl text-center text-white my-16 font-semibold`}
      >
        Testimonials from the road
      </h2>
      <div className="relative w-11/12 md:w-10/12 mx-auto" ref={containerRef}>
        <Carousel
          value={testimonials}
          itemTemplate={testimonialTemplate}
          numVisible={1}
          circular
          autoplayInterval={5000}
          autoPlay
          className="custom-carousel"
        />
      </div>
    </div>
  );
};

export default Testimonial;
