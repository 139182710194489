import Header from './header/Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import ContactWidget from "./ContactWidget";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow relative z-40">
        <Outlet />
      </main>
      <Footer />
      <ContactWidget />
    </div>
  );
};

export default Layout;
