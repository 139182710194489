import React, { useEffect, useRef } from "react";
import { BtnTransparent } from './shared/Button';

const TranslateComponent = ({props, className}) => {
  const googleTranslateRef = useRef(null);

  const handleTranslateToSpanish = (lang) => {
    if (window.google && window.google.translate) {
      const widgetContainer = document.querySelector('.goog-te-gadget');
      if (widgetContainer) {
        const select = widgetContainer.querySelector('.goog-te-combo');
        if (select) {
          select.value = lang;
          select.dispatchEvent(new Event('change'));
        }
      }
    }
  };

  useEffect(() => {
    let intervalId = null;
    const checkGoogleTranslate = () => {
      if (window.google && window.google.translate && window.google.translate.TranslateElement.InlineLayout) {
        clearInterval(intervalId);
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "es, en",
            layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
          },
          googleTranslateRef.current
        );
      }
    };
    intervalId = setInterval(checkGoogleTranslate, 100);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div id="google_translate_element" className="hidden" ref={googleTranslateRef}></div>
      {/*Temporarily disabled translation*/}
      {false && <BtnTransparent
        className={`md:text-lg ${className}`}
        name='Se Habla Español'
        handleClick={() => handleTranslateToSpanish("es")}
      />}
    </div>
  );
};

export default TranslateComponent;
