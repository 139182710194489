import ExpandablePointsSection from './ExpandablePointsSection';

const Faq = () => {

  const questions = [
    { title: "What is a DISPATCH SERVICE?", content: "A dispatching service is a transportation intermediary that helps trucking companies find loads, negotiate rates, and manage logistics. This allows the trucking company to focus on its core business of driving and delivering freight." },
    { title: "What types of carriers do you work with?", content: "We are a dispatching service that works with various carriers, including owner-operators, small to mid-sized fleets, and independent drivers with a variety of equipment." },
    { title: "How do you negotiate rates?", content: "Our company was started by drivers for drivers, and our dispatchers share the same ideals and mindset. They understand the challenges that drivers face and are dedicated to finding the best-paying loads from brokers and shippers. Our dispatchers are experienced drivers themselves, so they know what it's like to be out on the road. They understand the challenges that drivers face, and they are dedicated to finding the best-paying loads for our drivers." },
    { title: "Can I choose my own loads?", content: "We understand that sometimes a load won't work for you, and we respect your right to choose which loads you accept. We will never force you to take a load you don't agree with or approve." },
    { title: "Do you handle all the paperwork?", content: "Our company handles all documentation associated with the load offered and any setup required along with proof of delivery. Our company does not invoice or provide any collections related to the load. If you require assistance with invoicing or collections, we can refer you to a partner company for information about the services they provide." },
    { title: "How do you ensure compliance with regulations?", content: "Although our dispatchers have extensive knowledge of the rules and regulations that govern the transportation industry, we do not provide compliance services. However, we can refer you to reputable companies that specialize in providing compliance services to carriers. These companies can help you stay up-to-date and compliant with FMCSA regulations." },
    { title: "Do you help with factoring or fuel services?", content: "Unfortunately, we can't help you with that. However, we do know some trusted companies that you can reach out to and see what they offer." },
    { title: "Do you offer 24/7 support?", content: "Yes, we provide 24/7 support by having our support teams work in shifts. This ensures that someone is always available to answer your call. We understand that you may have questions or concerns at any time of day or night, and we want to make sure that you get the help you need when you need it." },
  ];

  return (
    <div className="bg-white py-2 relative z-30">
      <div className="w-5/6 mx-auto">
        <ExpandablePointsSection
          heading="FAQs about Trucking Dispatch"
          description="Before getting started with Quick Dispatch, explore some of the most common questions about truck dispatch that we've gathered for your convenience."
          points={questions}
        />
      </div>
    </div>
  );
};

export default Faq;
