import React, { useState, useRef, useEffect } from 'react';
import http from '../../../common/http';
import { getUserSession } from '../../../common/auth';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { BtnTransparent } from '../../shared/Button'; 
import { useToast } from '../../../contexts/ToastContext';
import { emailRegex, passwordRegex, usernameRegex } from '../../../utils/helper/string';
import { isSuperAdmin } from '../../../utils/helper/role';

const SignUpForm = ({ isOpen, onClose, openSignupForm, redirectRoute = '', headerName = '', userRole = '', userData = null }) => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const phoneInput = useRef(null);
  const referrerInput = useRef(null);
  const roleInput = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [referrerCode, setReferrerCode] = useState('');
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    setIsLoading(false);
    const params = new URLSearchParams(window.location.search);
    const code = params.get('referral_code');
    if (code) {
      setReferrerCode(code);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setEmail(userData.email || '');
      setFirstName(userData.first_name || '');
      setLastName(userData.last_name || '');
      setPhoneNumber(userData.phone_number || '');
      setRole(userRole || '');
    }
  }, [userData, userRole]);

  const roles = [
    { label: 'Carrier', value: 'carrier' },
    { label: 'Single Truck Owner', value: 'single_truck_owner' },
  ];

  if (!isOpen) return null;

  const redBox = "2px solid red";

  const handleInputChange = (setter, ref) => (e) => {
    setter(e.target.value);
    if (ref.current) {
      ref.current.style.border = '';
    }
  };

  const validateForm = () => {
    if (!email || (!password && !userRole) || !firstName || !lastName || !phoneNumber || (!userRole && !role)) {
      if (!email) emailInput.current.style.border = redBox;
      if (passwordInput.current && !password) passwordInput.current.style.border = redBox;
      if (!firstName) firstNameInput.current.style.border = redBox;
      if (!lastName) lastNameInput.current.style.border = redBox;
      if (!phoneNumber) phoneInput.current.style.border = redBox;
      showToast('error', 'Please fill in all the required fields.');
      return false;
    }
    if (!emailRegex.test(email) && !usernameRegex.test(email)) {
      showToast('error', 'Please enter a valid username or email address.');
      return false;
    }
    if (!userRole && !passwordRegex.test(password)) {
      showToast('error', 'Password should be at least 8 digits long.');
      return false;
    }
    return true;
  };

  const updateUser = () => {
    setIsLoading(true);
    const params = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      role: userRole,
    };
    http
      .put(`/api/v1/users/${userData.id}`, params)
      .then((res) => {        
        onClose();
      })
      .catch((err) => {
        showToast('error', 'Sorry, there was an error while updating this user.');
        setIsLoading(false);
      });
  };
  
  const submit = () => {
    if (validateForm()) {
      if (userData) {
        updateUser();
      } else {
        createUser();
      }
    }
  };

  const createUser = () => {
    setIsLoading(true);
    const systemGeneratedPass = Math.random().toString(36).slice(2, 10);
    const params = {
      user: {
        email: email,
        password: userRole ? systemGeneratedPass : password,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        referrer_code: referrerCode,
        role: userRole || role,
      }
    };
    http
      .post('/api/v1/users', params)
      .then((res) => {
        if (isSuperAdmin()) {
          showToast("success", "User has been successfully added.");
          onClose();
          return;
        }
        if (!userRole && res.data.user && res.data.token) {
          const user = res.data.user;
          localStorage.setItem('userId', user.id);
          const fullName = `${user.first_name} ${user.last_name}`;
          localStorage.setItem('fullName', fullName);
          localStorage.setItem('token', res.data.token);
          if (redirectRoute) {
            window.location.href = redirectRoute;
          } else {
            window.location.href = `/profile/${user.id}`;
          }
        } else {
          getUserSession();
        }
        onClose();
      })
      .catch((err) => {
        showToast('error', `Sorry, there was an error while signing up. ${err.response?.data?.message}`);
        setIsLoading(false);
      });
  };

  const footer = () => (
    <div>
      {!isSuperAdmin() && (
        <div className='relative flex justify-between items-center footer h-24 px-6'>
          <div className='text-white'>
            Already have an account?
          </div>
          <div>
            <BtnTransparent
              className='text-white font-semibold bg-[#484c4c] border-[#484c4c]'
              name='Sign in'
              handleClick={openSignupForm}
            />
          </div>
        </div>
      )}
    </div>
  );

  const buttonName = headerName ? (headerName === 'Edit User' ? 'Update' : 'Add') : 'Sign Up';

  return (
    <div>
      <Dialog
        header={headerName ?? 'Sign Up'}
        footer={footer}
        headerClassName="text-2xl font-normal text-white px-6 pt-8"
        visible={true}
        dismissableMask={true}
        draggable={false}
        closable={true}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={onClose}
      >
        <div className='px-6'>
          <div className='flex gap-4 mt-8'>
            <div className='w-1/2'>
              <label className='text-sm text-white'>First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={handleInputChange(setFirstName, firstNameInput)}
                ref={firstNameInput}
                className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
              />
            </div>
            <div className='w-1/2'>
              <label className='text-sm text-white'>Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={handleInputChange(setLastName, lastNameInput)}
                ref={lastNameInput}
                className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
              />
            </div>
          </div>
          <div className='flex gap-4 mt-4'>
            <div className='w-1/2'>
              <label className='text-sm text-white'>Email</label>
              <input
                type="text"
                value={email}
                onChange={handleInputChange(setEmail, emailInput)}
                ref={emailInput}
                className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
              />
            </div>
            <div className='w-1/2'>
              <label className='text-sm text-white'>Phone Number</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={handleInputChange(setPhoneNumber, phoneInput)}
                ref={phoneInput}
                className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
              />
            </div>
          </div>
          <div className='flex gap-4 mt-4'>
            {!isSuperAdmin() && (  
              <div className='w-1/2'>
                <label className='text-sm text-white'>Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={handleInputChange(setPassword, passwordInput)}
                  ref={passwordInput}
                  className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
                />
              </div>
            )}
            {!isSuperAdmin() && (
              <div className='w-1/2'>
                <label className='text-sm text-white'>Role</label>
                <Dropdown 
                  value={role} 
                  options={roles}
                  ref={roleInput}
                  onChange={(e) => setRole(e.value)} 
                  placeholder="Select a role" 
                  className="mt-1 w-full rounded-lg" 
                />
              </div>
            )}
          </div>
          {!isSuperAdmin() && (
            <div className='mt-4'>
              <label className='text-sm text-white'>Referrer Code (optional)</label>
              <input
                type="text"
                value={referrerCode}
                onChange={handleInputChange(setReferrerCode, referrerInput)}
                ref={referrerInput}
                className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 rounded-lg'
              />
            </div>
          )}
          <BtnTransparent
            className='text-white w-full mt-6 font-semibold'
            name={buttonName}
            handleClick={submit}
            isSubmitting={isLoading}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default SignUpForm;
