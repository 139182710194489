import React from 'react';
import { FaChevronRight } from "react-icons/fa";
import logo from '../assets/images/QuickDispatch-logo.png';
import { useNavigate, Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const footerMenu = [
    {
      label: 'Services',
      link: '/services',
    },
    {
      label: 'About Us',
      link: '/about-us',
    },
    {
      label: 'Privacy Policy',
      link: '/privacy-policy',
    },
    {
      label: 'Terms & Conditions',
      link: '/terms-and-conditions',
    }
  ];
  return (
    <footer className="bg-black text-white py-16 px-4 sticky bottom-0 w-full z-10">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-64 xs:gap-8">
        <div className="md:flex col-span-1 text-center md:text-left">
          <Link onClick={ () => navigate('/') }>
            <img src={logo} alt="Quick Dispatch" className="h-14 mr-4 hidden md:block" />
          </Link>
          <div>
            <h2 className="text-2xl font-bold mb-4">Quick Dispatch</h2>
            <p>Top-Tier Dispatch Solutions</p>  
          </div>
        </div>

        <div className="col-span-1 hidden md:block">
          <ul>
            {footerMenu.map((menu, index) => (
              <li className="mb-2" key={index}>
              <p onClick={() => {navigate(menu.link)}} className="flex items-center hover:text-moderate-orange cursor-pointer">
                <FaChevronRight className="mr-2" />
                {menu.label}
              </p>
            </li>
            ))}
          </ul>
        </div>

        <div className="col-span-1 text-center md:text-left">
          <p className="text-lg font-bold mb-4">Need Assistance?</p>
          <ul>
            <li className="mb-2">
              <a href="tel:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Call Us: (281) 790-9452</a>
            </li>
            <li className="mb-2">
              <a href="sms:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Text Us: (281) 790-9452</a>
            </li>
            <li><a href="mailto:info@dispatchquick.com" className="text-moderate-orange hover:text-light-moderate-orange">Email Us: info@dispatchquick.com</a></li>
          </ul>
        </div>
      </div>

        <div className="col-span-1 text-moderate-orange md:px-48">
          <div className="col-span-1 pt-4 text-center">
            <p className="mt-2">Copyright &copy; {currentYear} Quick Dispatch. <span className="block md:inline">All Rights Reserved.</span></p>
          </div>
        </div>
    </footer>
  );
}

export default Footer;
