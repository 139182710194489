import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useInView } from 'react-intersection-observer';

const ExpandablePointsSection = ({ heading, description, points }) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
  const { ref: descriptionRef, inView: descriptionInView } = useInView({ triggerOnce: true });
  const { ref: accordionRef, inView: accordionInView } = useInView({
    triggerOnce: true,
    skip: !isDescriptionVisible,
  });

  useEffect(() => {
    if (headerInView) {
      setIsHeaderVisible(true);
      setTimeout(() => {
        setIsDescriptionVisible(true);
      }, 700);
    }
  }, [headerInView]);

  useEffect(() => {
    if (descriptionInView) {
      setIsAccordionVisible(true);
    }
  }, [descriptionInView]);

  const headerTemplate = (headerText, index = "") => {
    return (
      <div className="flex justify-between items-center w-full py-2 text-lg">
        <span className="text-light-moderate-orange mr-2">{index}. {headerText}</span>
      </div>
    );
  };

  return (
    <section className="flex flex-col bg-cover bg-center py-16 z-40" style={{ backgroundImage: 'url(/section.webp)' }}>
      <div className="flex-1 mb-8 md:mb-0 md:pr-16">
        <div
          ref={headerRef}
          className={`transition-transform duration-700 ${
            isHeaderVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
          }`}
        >
          <h2 className="md:text-4xl text-2xl text-center mb-4 font-semibold">{heading}</h2>
        </div>
        <div
          ref={descriptionRef}
          className={`transition-transform duration-700 delay-300 ${
            isDescriptionVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
          }`}
        >
          <p className="mb-6 text-center text-lg">{description}</p>
        </div>
      </div>

      <div
        ref={accordionRef}
        className={`flex-1 transition-transform duration-700 delay-600 ${
          isAccordionVisible && accordionInView ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
        }`}
      >
        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          {points.map((point, index) => (
            <AccordionTab
              key={index}
              header={headerTemplate(point.title, index + 1)}
              headerClassName="text-light-moderate-orange cursor-pointer mt-2"
              contentClassName="bg-black bg-opacity-50"
            >
              <div className="p-4 text-lg">{point.content}</div>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default ExpandablePointsSection;
