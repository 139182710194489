import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import './DispatchForm.scss';
import { BtnTransparent } from "../shared/Button";
import http from '../../common/http';
import { useToast } from '../../contexts/ToastContext';

export const DispatchForm = ({ isViewDialog, handleModal }) => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    contactMethod: "E-mail",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showToast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, contactMethod: e.value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required.";
    if (!formData.contactMethod) newErrors.contactMethod = "Please select a contact method.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      setIsSubmitting(true);

      let submitUrl = "/api/v1/leads/contact-us";
      submitUrl = process.env.REACT_APP_BACKEND_API_ENDPOINT ? `${process.env.REACT_APP_BACKEND_API_ENDPOINT}${submitUrl}` : submitUrl;

      http
        .post(submitUrl, formData)
        .then(() => {
          hideModal();
          showToast('success', "Thank you for reaching out to us. We'll be in touch soon.");
          setIsSubmitting(false);
        })
        .catch((error) => {
          let errorMessage = error.response ? error.response.data.message : "Something went wrong. Please try again or contact support for any assistance."
          showToast('error', errorMessage);
          setIsSubmitting(false);
        });
    } else {
      showToast('error', 'Please fill out all the required fields');
    }
  };

  const renderFooter = () => (
    <div className="flex justify-end">
      <BtnTransparent
        name="Send"
        isSubmitting={isSubmitting}
        handleClick={handleSubmit}
      />
    </div>
  );

  const hideModal = () => {
    handleModal(false);
    setErrors({});
    setFormData(initialFormData);
  }

  return (
    <Dialog
      visible={isViewDialog}
      style={{ width: "90vw", maxWidth: "600px" }}
      onHide={() => hideModal()}
      closable
      draggable={false}
      header={
        <div className="px-6 flex justify-between items-center">
          <h2 className="text-lg font-bold text-white">Write To Start Dispatch</h2>
        </div>
      }
      footer={renderFooter()}
    >
      <div className="px-6 bg-grayish-black text-white rounded-lg">
        <p className="mb-6 text-light-moderate-orange flex items-center">
          <i className="pi pi-info-circle mr-3" />
          We are providing Over-the-Road logistics only
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="relative">
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className={`w-full p-2 bg-transparent border-b-2 ${errors.firstName ? 'border-red-500' : 'border-gray-500'} text-white focus:outline-none focus:border-moderate-orange peer`}
            />
            <label
              htmlFor="firstName"
              className={`absolute left-0 -translate-y-1/2 text-gray-400 transition-all duration-200 
              ${formData.firstName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-focus:top-0 peer-focus:text-xs"}`}
            >
              First Name*
            </label>
            {errors.firstName && <span className="text-red-500 text-xs">{errors.firstName}</span>}
          </div>

          <div className="relative">
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className={`w-full p-2 bg-transparent border-b-2 ${errors.lastName ? 'border-red-500' : 'border-gray-500'} text-white focus:outline-none focus:border-moderate-orange peer`}
            />
            <label
              htmlFor="lastName"
              className={`absolute left-0 -translate-y-1/2 text-gray-400 transition-all duration-200 
              ${formData.lastName ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-focus:top-0 peer-focus:text-xs"}`}
            >
              Last Name*
            </label>
            {errors.lastName && <span className="text-red-500 text-xs">{errors.lastName}</span>}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
          <div className="relative">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`w-full p-2 bg-transparent border-b-2 ${errors.email ? 'border-red-500' : 'border-gray-500'} text-white focus:outline-none focus:border-moderate-orange peer`}
            />
            <label
              htmlFor="email"
              className={`absolute left-0 -translate-y-1/2 text-gray-400 transition-all duration-200 
              ${formData.email ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-focus:top-0 peer-focus:text-xs"}`}
            >
              Email*
            </label>
            {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
          </div>

          <div className="relative">
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className={`w-full p-2 bg-transparent border-b-2 ${errors.phone ? 'border-red-500' : 'border-gray-500'} text-white focus:outline-none focus:border-moderate-orange peer`}
            />
            <label
              htmlFor="phone"
              className={`absolute left-0 -translate-y-1/2 text-gray-400 transition-all duration-200 
              ${formData.phone ? "top-0 text-xs" : "top-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base peer-focus:top-0 peer-focus:text-xs"}`}
            >
              Phone*
            </label>
            {errors.phone && <span className="text-red-500 text-xs">{errors.phone}</span>}
          </div>
        </div>

        <div className="mb-10">
          <label className="block mb-2">How should we contact you?</label>
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center">
              <RadioButton
                inputId="contactPhone"
                name="contactMethod"
                value="Phone"
                onChange={handleRadioChange}
                checked={formData.contactMethod === "Phone"}
                className="mr-2 custom-radio"
              />
              <label htmlFor="contactPhone" className="text-gray-400">
                Phone
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="contactEmail"
                name="contactMethod"
                value="E-mail"
                onChange={handleRadioChange}
                checked={formData.contactMethod === "E-mail"}
                className="mr-2"
              />
              <label htmlFor="contactEmail" className="text-gray-400">
                Email
              </label>
            </div>
          </div>
          {errors.contactMethod && <span className="text-red-500 text-xs">{errors.contactMethod}</span>}
        </div>

        <div>
          <p className="mb-2">Need Assistance? We're Just a Call, Text, or Email Away</p>
          <ul>
            <li className="mb-2">
              <a href="tel:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Call Us: (281) 790-9452</a>
            </li>
            <li className="mb-2">
              <a href="sms:2817909452" className="block text-moderate-orange hover:text-light-moderate-orange">Text Us: (281) 790-9452</a>
            </li>
            <li>
              <a href="mailto:info@dispatchquick.com" className="text-moderate-orange hover:text-light-moderate-orange">Email Us: info@dispatchquick.com</a>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  );
};
